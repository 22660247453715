/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useIntl } from "react-intl";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { useAuth } from "../../../../../app/modules/auth";

const SidebarMenuMain = () => {
  const intl = useIntl();
  const { currentUser, logout } = useAuth();

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="element-11"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
        iconType="svg"
      />
      {/* SDB-6(Name changed:Customer--->Tenant) */}
      {currentUser?.role === "sa" && (
        <SidebarMenuItem
          to="/tenants"
          icon="element-11"
          title="Tenant"
          iconType="font"
          fontIcon="fa fa-users"
        />
      )}
      {/* SDB[Name changed :POD --->Site] */}
      {currentUser?.role === "admin" && (
        <SidebarMenuItem
          to="/pod/site"
          // SDB-15:[Name Changed : POD --->Site]
          title="Site"
          iconType="font"
          fontIcon="fa-solid fa-sitemap"
        >
          {/* <SidebarMenuItem
              to="/pod/location"
              title="Location"
              hasBullet={true}
            /> */}
          {/* <SidebarMenuItem
              to="/pod/building"
              title="Building"
              hasBullet={true}
            />
            <SidebarMenuItem to="/pod/floor" title="Floor" hasBullet={true} />
            <SidebarMenuItem to="/pod/zone" title="Zone" hasBullet={true} />
            <SidebarMenuItem to="/pod/device" title="Device" hasBullet={true} /> */}
        </SidebarMenuItem>
      )}

      {currentUser?.role === "admin" && (
        <SidebarMenuItem
          to="/pod/device"
          // SDB-15:[Name Changed : POD --->Site]
          title="Device"
          iconType="font"
          fontIcon="fa-solid fa-computer"
        ></SidebarMenuItem>
      )}

      <SidebarMenuItem
        to="/modules"
        iconType="font"
        title={"SDB Services"}
        fontIcon="fa-solid fa-table-cells-large"
      />
      {currentUser?.role === "sa" && (
        <SidebarMenuItem
          to="/integrations"
          iconType="font"
          title={"Integrations"}
          fontIcon="fa-solid fa-gear"
        />
      )}

      {(currentUser?.role === "admin" || currentUser?.role === "sa") && (
        <React.Fragment>
          <SidebarMenuItem
            to="/apps/user-management/users"
            title={"Users"}
            fontIcon="fa-solid fa-user-shield"
            iconType="font"
          />
          {currentUser?.role === "sa" && (
            <SidebarMenuItem
              to="/user_role"
              // SDB9: (Title changed :"Permissions"--->"User Role")
              title={"User Role"}
              iconType="font"
              fontIcon="fa-solid fa-shield"
            />
          )}

          {/* // SDB-11: ("Settings" Menu Removed  */}

          {/* <SidebarMenuItemWithSub
            to="/settings"
            title="Setting"
            iconType="font"
            fontIcon="fa-solid fa-gear"
          >
            <SidebarMenuItem to="/settings/logs" title="Log" hasBullet={true} />
            
            {currentUser?.role === "admin" && (
              <SidebarMenuItem
                to="/settings/personalization"
                title="Personalization"
                hasBullet={true}
              />
            )}

          </SidebarMenuItemWithSub> */}
          {(currentUser?.role === "admin" || currentUser?.role === "sa") && (
            <SidebarMenuItem
              to="/auditreports"
              icon="element-11"
              // SDB-10: (Title changed :"Reports"--->"Audit Reports")
              title={"Audit Reports"}
              fontIcon="fa-solid fa-chart-simple"
              iconType="font"
            />
          )}
          {/* Newfields */}
          {/* SDB-New fields are added */}

          {currentUser?.role === "admin" && (
            <SidebarMenuItem
              to="/servicegroup"
              title="Service Group"
              iconType="font"
              fontIcon="fa-solid fa-object-group"
            ></SidebarMenuItem>
          )}

          {/* 
{currentUser?.role === "admin" && (

<SidebarMenuItem
          to=""
          title="Layout"
          iconType="font"
          fontIcon="fa-solid fa-ticket-simple"
          
        ></SidebarMenuItem>
        
        
)} */}
        </React.Fragment>
      )}
    </>
  );
};

export { SidebarMenuMain };
