import Stepper from "react-stepper-horizontal";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { toAbsoluteUrl } from "../../../sdb/helpers";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { addPublicUser } from "../pod/components/requests";
import { getUserByEmail } from "../apps/request";
import { getLocationByCustomer } from "../pod/components/requests";
import { getVisitorDataByHost } from "./request";
import { getUserByToken } from "../auth/core/_requests";
import { getUserById } from "../../pages/Tenants/requests";
import Webcam from "react-webcam";
import Select from "react-select";
import { useDebouncedCallback } from "../../utils";
import { TRUE } from "sass";
import "./main__.css";
import { MutableRefObject } from "react";
import { useAuth } from "../auth";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { UserModel as ImportedUserModel } from "../auth/core/_models";

import {
  getHostServiceData,
  addSVisitor,
  getSetupDataByCustomer,
} from "./request";

interface AddVisitorInfoProps {
  model_ref: MutableRefObject<HTMLDivElement | null>;
  modalCallBack: () => void;
}
interface UserModel extends ImportedUserModel {
  _id?: string;
}
interface UserModel {
  first_name: string;
  last_name: string;
  _id?: string;
}

interface HostModel {
  first_name: string;
  last_name: string;
  _id: string;
  role: string;
  location_id?: string;
}

interface Host {
  _id: string;
  first_name: string;
  last_name: string;
  location_id: string;
  role: string;
}

interface SelectOption {
  value: string;
  label: string;
}
interface ID {
  id: string;
}

const API_URL = process.env.REACT_APP_API_URL;

const editUserSchema = Yup.object().shape({
  full_name: Yup.string()
    .matches(/^[a-zA-Z]+$/, "Please enter alphabets")
    .required("First name is required"),

  last_name: Yup.string()
    .matches(/^[a-zA-Z]+$/, "Please enter alphabets")
    .required("Last name is required"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._@-]+$/,
      "Email address can only contain alphanumeric characters and special characters like - _ . @"
    )
    .email("Invalid email format")
    .required("Email is required"),

  identification: Yup.string()
    .matches(/^[A-Z0-9]*$/, "Please enter Valid Identification No")
    .required("Please enter Identification No"),

  phone_number: Yup.string().required("Contact Number is required"),

  company_name: Yup.string().required("Company name is required"),
  address: Yup.string().required("Address is required"),

  identification_type: Yup.object({
    value: Yup.string().required("Please select Identification Required"),
  }),
  // asset_information: Yup.object({
  //   value: Yup.string().required("asset_information is required"),
  // }),
  // visiting_date: Yup.string().required("Visiting_date type is required"),
  // visiting_time: Yup.string().required("Visiting_time type is required"),
  // duration: Yup.object({
  //   value: Yup.string().required("duration is required"),
  // }),
  // repeat_day_visiting_request: Yup.string().required(
  //   "Visiting_date type is required"
  // ),
});

const AddVisitorInfo: React.FC<AddVisitorInfoProps> = (
  props,
  { model_ref, modalCallBack }
) => {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [{ title: "1" }, { title: "2" }, { title: "3" }];
  const [selectedDuration, setSelectedDuration] = useState("");
  const handleDurationChange = (e) => {
    setSelectedDuration(e.value);
  };
  const [imgSrc, setImgSrc] = useState("");
  const [userInfo, setUserInfo]: any = useState(null);

  const history = useNavigate();
  const blankImg = toAbsoluteUrl("/media/avatars/blank.png");
  const lId = window.localStorage.getItem("locationId");
  const cId = window.localStorage.getItem("customerId");
  const [employeenamelist, setEmployeenamelist] = useState<string[]>([]);

  const [selectedItem, setSelectedItem] = useState(null);
  const [viewModal, setModalData] = useState(false);
  const [RequestType, setRequestType] = useState(["Self ", "On-Behalf"]);
  const [employee_name, setEmployeename] = useState([]);
  const [purpose_of_visit, setpurpose_of_visit] = useState([]);
  const [purpose, setpurpose] = useState([]);

  const [visitortype, setVisitortype] = useState([]);
  const [locationList, setLocationList] = useState<any[]>([]);
  const [currentUserlocation, setCurrentUserlocation] = useState<any>(null);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [duration, setDuration] = useState("");

  const [email, setEmail] = useState("");
  const [location, setlocation] = useState<string[]>([]);

  const [contactnumber, setcontactnumber] = useState("");
  const [identification_type, setIdentification_type] = useState([]);
  const [identificationnumber, setIdentificationnumber] = useState("");

  const [Assetlist, setAssetlist] = useState(["Asset 1", "Asset 2", "Asset 3"]);
  const [visittime, setVisittime] = useState("");
  const [visitdate, setVisitdate] = useState("");
  const [durationList, setDurationList] = useState([]);
  const [repeat_day_visiting_request, setRepeat_day_visiting_request] =
    useState("");
  const [address, setAddress] = useState("");
  // const [hostList, setHostList] = useState<HostModel[]>([]);
  const [isUserLoading, setLoading] = useState(false);
  const { currentUser, logout } = useAuth();
  const [preChecked, setPreChecked] = useState(false);
  const [customer_id, setCustomerId] = useState(
    currentUser.customer_id
      ? currentUser.customer_id
      : "64bd1e8214063a88c701285b"
  );
  const [location_id, setLocationId] = useState(
    currentUser.location_id?.[0]
      ? currentUser.location_id?.[0]
      : "64be5f5e750cf62596e44133" //"64bd2223a87d3e5d644d6b0d" //
  );

  const [setupData, setSetupData] = useState(null);
  const [durationValue, setDurationValue] = useState("");
  const [visitortypelist, setVisitortypelist] = useState([]);
  const [showDateTimeFields, setShowDateTimeFields] = useState(true);

  const [currentUserapi, setCurrentUserapi] =
    useState<ImportedUserModel | null>(null);

  const [currentTime2, setCurrentTime2] = useState(
    new Date().toISOString().split("T")[1].slice(0, 5)
  );
  const [visitorData] = useState<any>({
    request_Type: "",
    employee_name: "",
    purpose_of_visit: "",
    visitor_type: "",
    location: "",
    first_name: "",
    full_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    company_name: "",
    address: "",
    identification_type: "",
    identification: "",

    asset_information: "",
    customer_id: currentUser ? currentUser.customer_id : "",
    visiting_date_time: "",
    duration: "",
    // repeat_day_visiting_request: "",
    end_date_time: "",
  });

  const getHostData = async () => {
    try {
      const { data } = await getHostServiceData(customer_id, location_id);
      if (data) {
        const hostData = data.filter(
          (item: any) => item.role === "user" || item.role === "admin"
        );
        setHostList(hostData);

        hostData.forEach((host: any) => {
          // console.log("host location_id", host.location_id);
        });

        if (data[0] && data[0].first_name) {
          const employeenamelist = data[0].first_name.split(",");
          setEmployeenamelist(employeenamelist);
        }

        if (data[0] && data[0].location_id) {
          const location = data[0].location_id;
          setlocation(location);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  // console.log("ca",currentUser.customer_id);

  const getSetupData = async () => {
    try {
      const { data } = await getSetupDataByCustomer(customer_id);
      if (data) {
        if (data && data.duration && data.identification_type) {
          const durationList = data.duration.split(",");
          const identification_type = data.identification_type.split(",");
          setDurationList(durationList);
          setIdentification_type(identification_type);
        }
      }

      if (data && data.visitor_type) {
        const visitortypelist = data.visitor_type.split(",");
        setVisitortypelist(visitortypelist);
      }
      if (data && data.purpose_of_visit) {
        const purpose_of_visit = data.purpose_of_visit.split(",");
        setpurpose(purpose_of_visit);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
    }
  };

  const checks = (e) => {
    if (e.target.checked == true) {
      setPreChecked(true);
    } else {
      setPreChecked(false);
    }
  };
  const getLocations = async (customerId: number) => {
    try {
      const { data } = await getLocationByCustomer(customerId);
      if (data) {
        data.forEach((element) => {
          element["value"] = element._id;
          element["label"] = element.name;
        });
  
        const transformedData = data;
        
        // Update both state variables at once
        setTransformedOptions22(transformedData);
        setLocationList(transformedData);
  
        // console.log("dafaultlocationlist", transformedData);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    if (currentUser) {
      getLocations(currentUser?.customer_id);
    }
  }, []);

  const handleRequestTypeChange22 = (selectedOption: any) => {
    formik.setFieldValue("request_Type", selectedOption);

    if (selectedOption?.value === "Self") {
      if (locationList.length > 0) {
        const firstLocation = locationList[0];
        formik.setFieldValue("location", {
          value: firstLocation._id,
          label: firstLocation.name,
        });
      }
    } else {
      formik.setFieldValue("location", null);
    }
  };

  const getUserInfo = async (searchText) => {
    if (searchText && searchText.length > 5) {
      try {
        const { data } = await getUserByEmail(searchText);
        if (data) {
          setUserInfo(data);
          if (data.profile_pic) {
            setImgSrc(`${API_URL}/${data.profile_pic}`);
          }
          const userName = data.full_name.split(" ");
          formik.setFieldValue("full_name", data.full_name);
          // formik.setFieldValue("last_name", userName[1]);
          formik.setFieldValue("phone_number", data.phone_number);
          formik.setFieldValue("company_name", data.company_name);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const [openStartTime, setOpenStartTime] = useState(false);
  const [openEndTime, setOpenEndTime] = useState(false);

  const handleStartTimeClick = () => {
    setOpenStartTime(true);
  };

  const handleEndTimeClick = () => {
    setOpenEndTime(true);
  };

  const handleStartTimeClose = () => {
    setOpenStartTime(false);
  };

  const handleEndTimeClose = () => {
    setOpenEndTime(false);
  };

  const onSearch = useDebouncedCallback(() => {
    getUserInfo(email);
  }, 500);

  useEffect(() => {
    onSearch();
  }, [email]);

  const getVisitorInfo = async () => {
    const { data } = await getVisitorDataByHost(0, 20);
    // console.log(data);
  };

  useEffect(() => {
    const getCurrentUser = async () => {
      try {
        const { data } = await getUserByToken(customer_id);
        setCurrentUserapi(data as unknown as UserModel);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    getCurrentUser();
  }, [customer_id]);
  const combineDateTime = (date: string, time: string) => {
    const dateTime = dayjs(`${date}T${time}`, "YYYY-MM-DDTHH:mm");

    return dateTime.format();
  };

  const formik: any = useFormik({
    initialValues: visitorData,

    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const payload = {
        preApproved: preChecked,
      };
      // console.log("Form Submitted:", values);

      const params = {
        full_name: values.full_name,
        email: values.email.toLowerCase(),
        phone_number: values.phone_number,
        company_name: values.company_name,
        host_name: values.host_id.label,
        host_id: values.host_id.value,
        purpose_of_visit: values.purpose_of_visit
          ? values.purpose_of_visit.value
          : "",

        duration: values.duration.value,
        visiting_date_time: values.visiting_date_time + "T" + values.visit_time,
        end_date_time: values.visiting_date_time + "T" + values.end_date_time,
        meta: {
          identification: values.identification,
          identification_type: values.identification_type
            ? values.identification_type.value
            : "",
          asset_information: values.asset_information,
        },
        pre_approved: preChecked,
        location_id: values.location.value,
        customer_id: currentUser.customer_id,
        location_name: values.location.label,
      };
      const param = {
        full_name: values.full_name,
        email: values.email.toLowerCase(),
        phone_number: values.phone_number,
        company_name: values.company_name,
        host_name: values.host_id.label,

        host_id: values.host_id.value,
        purpose_of_visit: values.purpose_of_visit
          ? values.purpose_of_visit.value
          : "",
        duration: values.duration.value,
        visiting_date_time: values.visiting_date_time + "T" + values.visit_time,
        pre_approved: preChecked,

        meta: {
          identification: values.identification,
          identification_type: values.identification_type
            ? values.identification_type.value
            : "",
          asset_information: capitalizeFirstLetter(values.asset_information),
        },
        location_id: values.location.value,
        location_name: values.location.label,

        customer_id: currentUser.customer_id,
      };

      // console.log(params);

      try {
        //await createUser(values)
        /*  visitorData.full_name = values.full_name;
        visitorData.email = values.email;
        visitorData.phone_number = values.phone_number;
        visitorData.company_name = values.company_name;
        visitorData.host_id = values.host_id.value;
        visitorData.duration = values.duration.value;
        visitorData.visiting_date_time = values.visiting_date_time;
        visitorData.end_date_time = values.end_date_time;
        visitorData.pre_approved = preChecked; */
        addSVisitor(values.end_date_time ? params : param)
          .then((daa: any) => {
            props.modalCallBack();
          })

          .then((daa: any) => {
            window.location.reload();
            Swal.fire(
              "User Visiting Request",
              "User visiting request has been sent succefully!",
              "success"
            );

            const id = cId;

            history(`/modules/visitor-management?id=${id}`);
          })

          .then((daa: any) => {
            getVisitorInfo();
          })
          .catch((error: any) => {
            let errorMessage = "An unknown error occurredd";

            if (error.response?.data?.detail) {
              const details = error.response.data.detail;

              if (Array.isArray(details) && details.length > 0) {
                errorMessage = details.map((d: any) => d.msg).join(", ");
              } else {
                errorMessage = details;
              }
            }

            Swal.fire("Visitor Info!", errorMessage, "error");
          });
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(true);
        cancel(true);
      }
    },
  });
  useEffect(() => {
    getHostData();
    getSetupData();
  }, []);

  const handleSelectChange = (e) => {
    formik.setFieldValue("request_Type", e);
    // setRequestType(e);
  };

  useEffect(() => {
    getVisitorInfo();
  }, []);

  // .log(cId);
  const [showTooltipfirstname, setShowTooltipfirstname] = useState(false);
  const [showTooltipLastName, setShowTooltipLastName] = useState(false);
  const [showTooltipEmail, setShowTooltipEmail] = useState(false);
  const [ShowTooltipidentificationNo, setShowTooltipidentificationNo] =
    useState(false);
  const [ShowTooltipidentificationType, setShowTooltipidentificationType] =
    useState(false);
  const [ShowTooltipcontactnumber, setShowTooltipcontactnumber] =
    useState(false);

  const [ShowTooltipaddress, setShowTooltipaddress] = useState(false);
  const [ShowCopanyname, setShowCopanyname] = useState(false);
  const [showvisitingDate, setshowvisitingDate] = useState(false);

  const hasErrorEmail = formik.touched.email && formik.errors.email;
  const hasErrorLastName = formik.touched.last_name && formik.errors.last_name;
  const hasErrorfirstName = formik.touched.full_name && formik.errors.full_name;
  const hasErroridentificationno =
    formik.touched.identification && formik.errors.identification;
  const hasErroridentificationtype =
    formik.touched.identification_type && formik.errors.identification_type;

  const hasErrorcontactnumber =
    formik.touched.phone_number && formik.errors.phone_number;

  const hasErroraddress = formik.touched.address && formik.errors.address;

  const hasErrorcompanyname =
    formik.touched.company_name && formik.errors.company_name;

  const hasErrordate =
    formik.touched.visiting_date && formik.errors.visiting_date;

  const renderTooltipEmail = (props) => (
    <Tooltip id="emailTooltip" {...props}>
      {formik.errors.email}
    </Tooltip>
  );

  const renderTooltipLastName = (props) => (
    <Tooltip id="lastNameTooltip" {...props}>
      {formik.errors.last_name}
    </Tooltip>
  );

  const renderTooltipfirstname = (props) => (
    <Tooltip id="fistnameTooltip" {...props}>
      {formik.errors.full_name}
    </Tooltip>
  );

  const renderTooltipidentiicationNo = (props) => (
    <Tooltip id="identificationTooltip" {...props}>
      {formik.errors.identification}
    </Tooltip>
  );

  const renderTooltipidentiicationType = (props) => (
    <Tooltip id="identificationTypeTooltip" {...props}>
      {formik.errors.identification_type.value}
    </Tooltip>
  );

  const renderTooltipcontactnumber = (props) => (
    <Tooltip id="phonenumebrTooltip" {...props}>
      {formik.errors.phone_number}
    </Tooltip>
  );

  const renderTooltipcompany = (props) => (
    <Tooltip id="identificationTypeTooltip" {...props}>
      {formik.errors.company_name}
    </Tooltip>
  );

  const renderTooltipaddress = (props) => (
    <Tooltip id="identificationTypeTooltip" {...props}>
      {formik.errors.address}
    </Tooltip>
  );

  const renderTooltipDATE = (props) => (
    <Tooltip id="dATETooltip" {...props}>
      {formik.errors.Visiting_date}
    </Tooltip>
  );
  const ValidationTooltip = () => (
    <Tooltip id="validation-tooltip">
      Please enter numbers and uppercase letters only.
    </Tooltip>
  );
  const [showTooltip, setShowTooltip] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value.toUpperCase();
    if (/^[A-Z0-9]*$/.test(value) && value.length <= 12) {
      formik.setFieldValue("identification", value);
      setIdentificationnumber(value);
      setShowTooltip(false);
    } else {
      setShowTooltip(true);
    }
  };

  const today = new Date().toISOString().split("T")[0];
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const currentTime = `${hours}:${minutes}`;

  const [showTooltips, setShowTooltips] = useState(true);

  const handleClose = () => {
    setShowTooltips(false);
    setModalData(false);
    formik.resetForm()
  };

  function capitalizeFirstLetter(input) {
    if (!input) return "";
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
  }

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      overflowY: "auto",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      margin: 0,
    }),
    option: (provided, state) => ({
      ...provided,
    }),
    control: (provided) => ({
      ...provided,
    }),
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    formik.setFieldValue("precheckbox", isChecked);
    setPreChecked(isChecked);
  };

  const handleRequestTypeChange = (selectedOption: any) => {
    formik.setFieldValue("request_Type", selectedOption);

    if (selectedOption.value === "Self") {
      if (locationList.length > 0) {
        const firstLocation = locationList[0];
        formik.setFieldValue("location", {
          value: firstLocation._id,
          label: firstLocation.name,
        });
      } else {
        formik.setFieldValue("location", null);
      }

      if (currentUser) {
        const fullName = `${currentUser.first_name} ${currentUser.last_name}`;
        const matchedHost = hostList.find(
          (host) => `${host.first_name} ${host.last_name}` === fullName
        );

        if (matchedHost) {
          formik.setFieldValue("host_id", {
            value: matchedHost._id,
            label: fullName,
          });
        } else {
          formik.setFieldValue("host_id", null);
        }
      }
    } else {
      formik.setFieldValue("location", null);
      formik.setFieldValue("host_id", null);
    }
  };

  useEffect(() => {
    if (formik.values.visit_time) {
      const timeString =
        typeof formik.values.visit_time === "string"
          ? formik.values.visit_time
          : formik.values.visit_time.format("HH:mm");

      const [hours, minutes] = timeString.split(":").map(Number);

      let endMinutes = minutes + 1;
      let endHours = hours;

      if (endMinutes === 60) {
        endMinutes = 0;
        endHours = (endHours + 1) % 24;
      }

      const formattedEndTime = `${String(endHours).padStart(2, "0")}:${String(
        endMinutes
      ).padStart(2, "0")}`;
      formik.setFieldValue("end_date_time", formattedEndTime);
      // console.log(formattedEndTime);
    }
  }, [formik.values.visit_time]);

  const [selectedHostId, setSelectedHostId] = useState<ID | null>(null);
  const [userData, setUserData] = useState<any>(null);
  const [hostList, setHostList] = useState<any[]>([]);
  const [transformedOptions, setTransformedOptions] = useState([]);
  const [transformedOptions22, setTransformedOptions22] = useState<any[]>([]);
  const [isOptionsLoaded, setIsOptionsLoaded] = useState<boolean>(false);
  
  const handleChangelocation = (selectedOption: SelectOption) => {
    if (selectedOption) {
      // formik.setFieldValue("host_id", selectedOption);

      const hostId: ID = selectedOption.value as unknown as ID;
      setSelectedHostId(hostId);

      const selectedHost = hostList.find(
        (host) => host._id === selectedOption.value
      );
     if (transformedOptions22.length === 0) {
      return;
  }

  const firstLocation = transformedOptions22[0] || null;
      console.log(firstLocation);
      
      formik.setFieldValue("location", firstLocation);
      if (selectedHost) {
        // console.log("Selected Host Details:", {
        //   id: selectedHost._id,
        //   firstName: selectedHost.first_name,
        //   lastName: selectedHost.last_name,
        //   locationId: selectedHost.location_id,
        // });
      } else {
        console.error("Selected host not found in the host list.");
      }
    } else {
      console.error("Selected option is null or undefined.");
    }
  };

  useEffect(() => {
    const getCurrentUser = async () => {
      if (selectedHostId) {
        try {
          const response = await getUserById(selectedHostId as any);

          const options = response.location.map((loc) => ({
            value: loc._id,
            label: loc.name,
          }));
          setTransformedOptions(options);
          setTransformedOptions22(options);

          // console.log("options",options);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    getCurrentUser();
  }, [selectedHostId]);

  const { values, setFieldValue, touched, errors } = formik;

  const isToday = dayjs(values.visiting_date_time).isSame(dayjs(), "day");

  const minTime = isToday ? dayjs().startOf("minute") : dayjs().startOf("day");

  const visitingTime = dayjs(formik.values.visit_time);
  const endDateTime = formik.values.end_date_time;

  // const minTimefortime = visitingTime ? visitingTime.add(1, "minute") : dayjs();
  const [minTimefortime, setMinTimefortime] = useState(dayjs().startOf("day"));
  useEffect(() => {
    if (formik.values.visit_time) {
      setMinTimefortime(dayjs(`2022-01-01T${formik.values.visit_time}`));
    }
  }, [formik.values.visit_time]);

  const handleTimeChange = (newValue) => {
    if (newValue) {
      const formattedTime = newValue.format("HH:mm");
      formik.setFieldValue("visit_time", formattedTime);
      setOpenStartTime(false);
    }
  };
  function getSectionComponent() {
    switch (activeStep) {
      case 0:
        return (
          <div className="row  mt-3">
            <div className="col-1"></div>
            <div className="col-12 register">
              <div className="card" style={{ marginBottom: "60px" }}>
                <h2 className="card-header fw-bolder">Add Visit Request</h2>
                <form onSubmit={formik.handleSubmit}>
                  {customer_id && location_id && (
                    <React.Fragment>
                      <div className="card-body py-3">
                        <div className="row">
                          <>
                            <div className="col-6 mb-3">
                              <label className="col-sm-4 required fw-bold fs-6">
                                Request Type
                              </label>
                              <Select
                                {...formik.getFieldProps("request_Type")}
                                options={[
                                  { value: "Self", label: "Self" },
                                  { value: "On-Behalf", label: "On-Behalf" },
                                ]}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={handleRequestTypeChange}
                                onFocus={() => {
                                  formik.setFieldTouched("request_Type", true);
                                }}
                              />
                              {formik.touched.request_Type &&
                                formik.errors.request_Type && (
                                  <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                      <span role="alert">
                                        {formik.errors.request_Type.value}
                                      </span>
                                    </div>
                                  </div>
                                )}
                            </div>
                            <div className="col-6 mb-3">
                              <label className="col-sm-12 required fw-bold fs-6">
                                Employee Name
                              </label>
                              <Select
                                {...formik.getFieldProps("host_id")}
                                name="host_id"
                                options={hostList.map((item) => ({
                                  value: item._id,
                                  label: `${item.first_name} ${item.last_name}`,
                                }))}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(
                                  selectedOption: SelectOption
                                ) => {
                                  if (selectedOption) {
                                    handleChangelocation(selectedOption);
                                    formik.setFieldValue(
                                      "host_id",
                                      selectedOption
                                    ); 
                                  }
                                }}  
                                
                                onFocus={() => {
                                  formik.setFieldTouched("host_id", true);
                                }}
                                isDisabled={
                                  formik.values.request_Type?.value === "Self"
                                }
                              />

                              {formik.touched.host_id && formik.errors.host_id && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    <span role="alert">
                                      {formik.errors.host_id.value}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>

                          <div className="col-6 mb-3">
                            <label className="col-sm-4 fw-bold fs-6 required">
                              Visitor Type
                            </label>
                            <Select
                              {...formik.getFieldProps("visitor_type")}
                              name="visitor_type"
                              options={visitortypelist.map((item: any, i) => ({
                                value: item,
                                label: item,
                              }))}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              styles={customStyles}
                              onChange={(e) => {
                                formik.setFieldValue("visitor_type", e);
                              }}
                              onFocus={(e) => {
                                formik.setFieldTouched("visitor_type", true);
                              }}
                            />
                            {formik.touched.visitor_type &&
                              formik.errors.visitor_type && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    <span role="alert">
                                      {formik.errors.visitor_type.value}
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>
                          <div className="col-6 mb-3">
                            <label className="col-sm-6 required fw-bold fs-6">
                              Purpose Of Visit
                            </label>
                            <Select
                              {...formik.getFieldProps("purpose_of_visit")}
                              name="purpose_of_visit"
                              options={purpose.map((item: any, i) => ({
                                value: item,
                                label: item,
                              }))}
                              styles={customStyles}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => {
                                formik.setFieldValue("purpose_of_visit", e);
                              }}
                              onFocus={(e) => {
                                formik.setFieldTouched(
                                  "purpose_of_visit",
                                  true
                                );
                              }}
                            />
                            {formik.touched.purpose_of_visit &&
                              formik.errors.purpose_of_visit && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    <span role="alert">
                                      {formik.errors.purpose_of_visit.value}
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>
                          <div className="col-6 mb-3">
                            <label className="col-sm-4 fw-bold fs-6 required">
                              Location
                            </label>
                            <Select
                              {...formik.getFieldProps("location")}
                              name="location"
                              options={transformedOptions}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => {
                                formik.setFieldValue("location", e);
                              }}
                              onFocus={() => {
                                formik.setFieldTouched("location", true);
                              }}
                              styles={customStyles}
                              isDisabled={
                                formik.values.request_Type?.value === "Self"
                              }
                            />
                            {formik.touched.location && formik.errors.location && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert">
                                    {formik.errors.location.value}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>

                          <div
                            className="col-6 mb-3"
                            style={{ marginTop: "0.7cm" }}
                          >
                            <input
                              {...formik.getFieldProps("precheckbox")}
                              className="form-check-input"
                              type="checkbox"
                              data-kt-check-target="#kt_table_users .form-check-input"
                              checked={formik.values.precheckbox}
                              onChange={handleCheckboxChange}
                              disabled={
                                formik.values.request_Type.value === "On-Behalf"
                              }
                            />{" "}
                            <label
                              className="col-sm-4 fw-bold fs-6"
                              style={{
                                color:
                                  formik.values.request_Type.value ===
                                  "On-Behalf"
                                    ? "grey"
                                    : "initial",
                              }}
                            >
                              Is pre-approved
                            </label>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  {(!customer_id || !location_id) && (
                    <React.Fragment>
                      <div className="card-body">
                        <p className="text-start mt-4 mb-4 badge-light-danger">
                          Customer/Location Id missing.
                        </p>
                      </div>
                    </React.Fragment>
                  )}
                </form>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="row  mt-4">
            <div className="col-12 register">
              <div className="card">
                <h2 className="card-header fw-bolder">Add Visitor Request</h2>
                <form
                  onSubmit={formik.handleSubmit}
                  // onChange={handleFormChange}
                >
                  {customer_id && location_id && (
                    <React.Fragment>
                      <div className="card-body">
                        <div className="card-body py-3">
                          <div className="row">
                            <div className="col-6 mb-3">
                              <label
                                // htmlFor="inputPassword3"
                                className=" required fw-bold fs-6"
                              >
                                First Name
                              </label>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  showTooltipfirstname && hasErrorfirstName ? (
                                    renderTooltipfirstname
                                  ) : (
                                    <></>
                                  )
                                }
                                show={
                                  showTooltipfirstname &&
                                  hasErrorfirstName &&
                                  showTooltips
                                }
                              >
                                <input
                                  placeholder="First Name"
                                  {...formik.getFieldProps("full_name")}
                                  type="text"
                                  name="full_name"
                                  autoComplete="off"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    formik.setFieldValue("full_name", value);
                                    setFirstname(value);
                                  }}
                                  value={formik.values.full_name}
                                  onFocus={(e) => {
                                    formik.setFieldTouched("full_name", true);
                                  }}
                                  maxLength={15}
                                  className={clsx(
                                    "form-control form-control-solid mb-3 mb-lg-0",
                                    {
                                      "is-invalid":
                                        formik.touched.full_name &&
                                        formik.errors.full_name,
                                    }
                                  )}
                                />
                              </OverlayTrigger>
                            </div>
                            <div className="col-6 mb-3">
                              <label className="required fw-bold fs-6">
                                Last Name
                              </label>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  showTooltipLastName && hasErrorLastName ? (
                                    renderTooltipLastName
                                  ) : (
                                    <></>
                                  )
                                }
                                show={
                                  showTooltipLastName &&
                                  hasErrorLastName &&
                                  showTooltips
                                }
                              >
                                <input
                                  placeholder="Last Name"
                                  {...formik.getFieldProps("last_name")}
                                  type="text"
                                  name="last_name"
                                  autoComplete="off"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    formik.setFieldValue("last_name", value);
                                    setLastname(value);
                                  }}
                                  value={formik.values.last_name}
                                  onFocus={() =>
                                    formik.setFieldTouched("last_name", true)
                                  }
                                  maxLength={15}
                                  className={clsx(
                                    "form-control form-control-solid mb-3 mb-lg-0",
                                    {
                                      "is-invalid":
                                        formik.touched.last_name &&
                                        formik.errors.last_name,
                                    }
                                  )}
                                />
                              </OverlayTrigger>
                            </div>
                            <div className="col-6 mb-3">
                              <label className="col-sm-6 required fw-bold fs-6">
                                Email Address{" "}
                              </label>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  showTooltipEmail && hasErrorEmail ? (
                                    renderTooltipEmail
                                  ) : (
                                    <></>
                                  )
                                }
                                show={
                                  showTooltipEmail &&
                                  hasErrorEmail &&
                                  showTooltips
                                }
                              >
                                <input
                                  placeholder="Email Address"
                                  type="email"
                                  maxLength={50}
                                  name="email"
                                  // maxLength={}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    formik.setFieldValue("email", value);
                                    setEmail(value);
                                  }}
                                  value={formik.values.email}
                                  onFocus={(e) => {
                                    formik.setFieldTouched("email", true);
                                  }}
                                  autoComplete="off"
                                  className={clsx(
                                    "form-control form-control-solid mb-3 mb-lg-0",
                                    {
                                      "is-invalid":
                                        formik.touched.email &&
                                        formik.errors.email,
                                    }
                                  )}
                                  // data-tip={hasError ? formik.errors.email : ""}
                                  // data-for="emailTooltip"
                                />
                              </OverlayTrigger>
                            </div>
                            <div className="col-6 mb-3">
                              <label className="col-sm-6 required fw-bold fs-6">
                                Contact Number
                              </label>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  ShowTooltipcontactnumber &&
                                  hasErrorcontactnumber ? (
                                    renderTooltipcontactnumber
                                  ) : (
                                    <></>
                                  )
                                }
                                show={
                                  ShowTooltipcontactnumber &&
                                  hasErrorcontactnumber &&
                                  showTooltips
                                }
                              >
                                <PhoneInput
                                  country={"in"}
                                  value={formik.values.phone_number}
                                  onChange={(phone) =>
                                    formik.setFieldValue("phone_number", phone)
                                  }
                                  inputStyle={{
                                    padding: "21px 50px",
                                    width: "100%",
                                  }}
                                  inputProps={{
                                    name: "phone_number",
                                    required: true,
                                    autoFocus: true,
                                    className: clsx(
                                      "form-control form-control-solid mb-3 mb-lg-0",
                                      {
                                        "is-invalid":
                                          formik.touched?.phone_number &&
                                          formik.errors?.phone_number,
                                      },
                                      {
                                        "is-valid":
                                          formik.touched?.phone_number &&
                                          !formik.errors?.phone_number,
                                      }
                                    ),
                                  }}
                                />
                              </OverlayTrigger>
                            </div>

                            <div className="col-6 mb-3">
                              <label className="col-sm-6 required fw-bold fs-6">
                                Company Name{" "}
                              </label>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  ShowCopanyname && hasErrorcompanyname ? (
                                    renderTooltipcompany
                                  ) : (
                                    <></>
                                  )
                                }
                                show={
                                  ShowCopanyname &&
                                  hasErrorcompanyname &&
                                  showTooltips
                                }
                              >
                                <input
                                  placeholder="Company Name"
                                  type="text"
                                  name="company_name"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    formik.setFieldValue("company_name", value);
                                    setEmail(value);
                                  }}
                                  value={formik.values.company_name}
                                  onFocus={(e) => {
                                    formik.setFieldTouched(
                                      "company_name",
                                      true
                                    );
                                  }}
                                  autoComplete="off"
                                  className={clsx(
                                    "form-control form-control-solid mb-3 mb-lg-0",
                                    {
                                      "is-invalid":
                                        formik.touched.company_name &&
                                        formik.errors.company_name,
                                    }
                                  )}
                                />
                              </OverlayTrigger>
                            </div>
                            <div className="col-6 mb-3">
                              <label className="col-sm-4 required fw-bold fs-6">
                                Address
                              </label>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  ShowTooltipaddress && hasErroraddress ? (
                                    renderTooltipaddress
                                  ) : (
                                    <></>
                                  )
                                }
                                show={
                                  ShowTooltipaddress &&
                                  hasErroraddress &&
                                  showTooltips
                                }
                              >
                                <input
                                  placeholder="Address"
                                  type="email"
                                  name="address"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    formik.setFieldValue("address", value);
                                    setEmail(value);
                                  }}
                                  value={formik.values.address}
                                  onFocus={(e) => {
                                    formik.setFieldTouched("  ress", true);
                                  }}
                                  autoComplete="off"
                                  className={clsx(
                                    "form-control form-control-solid mb-3 mb-lg-0",
                                    {
                                      "is-invalid":
                                        formik.touched.address &&
                                        formik.errors.address,
                                    }
                                  )}
                                />
                              </OverlayTrigger>
                            </div>

                            <div className="col-6 mb-3">
                              <label className="col-sm-8 fw-bold fs-6 required">
                                Identification Required
                              </label>
                              <OverlayTrigger
                                placement="top"
                                // overlay={renderTooltipidentiicationNo}

                                overlay={
                                  ShowTooltipidentificationType &&
                                  hasErroridentificationtype ? (
                                    renderTooltipidentiicationType
                                  ) : (
                                    <></>
                                  )
                                }
                                show={
                                  ShowTooltipidentificationType &&
                                  hasErroridentificationtype &&
                                  showTooltips
                                }
                              >
                                <Select
                                  {...formik.getFieldProps(
                                    "identification_type"
                                  )}
                                  name="identification_type"
                                  options={identification_type.map(
                                    (item: any, i) => ({
                                      value: item,
                                      label: item,
                                    })
                                  )}
                                  styles={customStyles}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "identification_type",
                                      e
                                    );
                                  }}
                                  onFocus={(e) => {
                                    formik.setFieldTouched(
                                      "identification_type",
                                      true
                                    );
                                  }}
                                />
                              </OverlayTrigger>
                              {/* {formik.touched.identification_type &&
                                formik.errors.identification_type && (
                                  <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                      <span role="alert">
                                        {
                                          formik.errors.identification_type
                                            .value
                                        }
                                      </span>
                                    </div>
                                  </div>
                                )} */}
                            </div>

                            <div className="col-6 mb-3">
                              <label className="col-sm-12 fw-bold fs-6 required">
                                Identification No
                              </label>
                              <OverlayTrigger
                                placement="top"
                                // overlay={renderTooltipidentiicationNo}

                                overlay={
                                  ShowTooltipidentificationNo &&
                                  hasErroridentificationno ? (
                                    renderTooltipidentiicationNo
                                  ) : (
                                    <></>
                                  )
                                }
                                show={
                                  showTooltips &&
                                  ShowTooltipidentificationNo &&
                                  hasErroridentificationno &&
                                  ValidationTooltip
                                }
                              >
                                <input
                                  placeholder="Identification"
                                  type="text"
                                  name="identification"
                                  onChange={handleChange}
                                  value={formik.values.identification}
                                  onFocus={() =>
                                    formik.setFieldTouched(
                                      "identification",
                                      true
                                    )
                                  }
                                  maxLength={12}
                                  {...formik.getFieldProps("identification")}
                                  autoComplete="off"
                                  className={clsx(
                                    "form-control form-control-solid mb-3 mb-lg-0",
                                    {
                                      "is-invalid":
                                        formik.touched.identification &&
                                        formik.errors.identification,
                                    }
                                  )}
                                  // onKeyDown={handleKeyDown}
                                />
                              </OverlayTrigger>
                              {formik.touched.identification &&
                                formik.errors.identification && (
                                  <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                      <span role="alert">
                                        {/* {formik.errors.identification} */}
                                      </span>
                                    </div>
                                  </div>
                                )}
                            </div>

                            <div className="col-6 mb-3">
                              <label className="col-sm-12 fw-bold fs-6">
                                Asset Information
                              </label>
                              <textarea
                                placeholder="Asset Information"
                                name="visitorasset"
                                {...formik.getFieldProps("asset_information")}
                                autoComplete="off"
                                onChange={(e) => {
                                  const value = e.target.value;
                                  formik.setFieldValue(
                                    "asset_information",
                                    value
                                  );
                                  setAddress(value);
                                }}
                                value={formik.values.asset_information}
                                onFocus={(e) => {
                                  formik.setFieldTouched(
                                    "asset_information",
                                    true
                                  );
                                }}
                                className={clsx(
                                  "form-control form-control-solid mb-3 mb-lg-0",
                                  {
                                    "is-invalid":
                                      formik.touched.asset_information &&
                                      formik.errors.asset_information,
                                  }
                                )}
                              />
                              {formik.touched.asset_information &&
                                formik.errors.asset_information && (
                                  <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                      <span role="alert">
                                        {formik.errors.asset_information.value}
                                      </span>
                                    </div>
                                  </div>
                                )}
                            </div>

                            {/* end */}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  {(!customer_id || !location_id) && (
                    <React.Fragment>
                      <div className="card-body">
                        <p className="text-start mt-4 mb-4 badge-light-danger">
                          Customer/Location Id missing.
                        </p>
                      </div>
                    </React.Fragment>
                  )}
                </form>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="row  mt-4">
            <div className="col-12 register">
              <div className="card">
                <h2 className="card-header fw-bolder">Add Visitor Timings</h2>
                <form
                  onSubmit={formik.handleSubmit}
                  // onChange={handleFormChange}
                >
                  {customer_id && location_id && (
                    <React.Fragment>
                      <div className="card-body">
                        <div className="card-body py-3">
                          <div className="row">
                            <div className="col-6 mb-3">
                              <>
                                <label className="col-sm-12 required fw-bold fs-6">
                                  Visiting Date
                                </label>
                                <input
                                  placeholder="Select date"
                                  type="date"
                                  onFocus={(e: any) => {
                                    e.target.showPicker();
                                  }}
                                  name="visiting_date_time"
                                  {...formik.getFieldProps(
                                    "visiting_date_time"
                                  )}
                                  min={today}
                                  autoComplete="off"
                                  className={clsx(
                                    "form-control form-control-solid mb-3 mb-lg-0",
                                    {
                                      "is-invalid":
                                        formik.touched.visiting_date_time &&
                                        formik.errors.visiting_date_time,
                                    }
                                  )}
                                />
                                {/*  {formik.touched.end_date_time &&
      formik.errors.end_date_time && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <span role="alert">
              {formik.errors.end_date_time}
            </span>
          </div>
        </div>
      )} */}
                              </>
                            </div>
                            <div
                              className="col-6 mb-3"
                              onClick={handleStartTimeClick}
                            >
                              <label className="col-sm-12 required fw-bold fs-6">
                                Visiting Time
                              </label>

                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  // open={openStartTime}
                                  // onOpen={() => setOpenStartTime(true)}
                                  // onClose={handleStartTimeClose}
                                  value={
                                    formik.values.visit_time
                                      ? dayjs(formik.values.visit_time)
                                      : null
                                  }
                                  closeOnSelect={false}
                                  onChange={(newValue) => {
                                    const formattedTime = newValue
                                      ? newValue.format("HH:mm")
                                      : "";
                                    formik.setFieldValue(
                                      "visit_time",
                                      formattedTime
                                    );
                                  }}
                                  minTime={minTime}
                                  views={["hours", "minutes"]}
                                />
                              </LocalizationProvider>
                            </div>
                            {showDateTimeFields && (
                              <div className="row mb-3">
                                <div className="col-6">
                                  <label className="col-sm-12 required fw-bold fs-6">
                                    Duration
                                  </label>
                                  <Select
                                    {...formik.getFieldProps("duration")}
                                    name="duration"
                                    options={durationList.map(
                                      (item: any, i) => ({
                                        value: item,
                                        label: item,
                                      })
                                    )}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(e) => {
                                      formik.setFieldValue("duration", e);
                                      handleDurationChange(e);
                                    }}
                                    onFocus={(e) => {
                                      formik.setFieldTouched("duration", true);
                                    }}
                                  />
                                </div>
                                {selectedDuration === "Custom" && (
                                  <div
                                    className="col-6"
                                    // style={{marginLeft:"1px"}}
                                    onClick={handleEndTimeClick}
                                  >
                                    <label
                                      className="col-sm-12 required fw-bold fs-6 "
                                      style={{ marginLeft: "11px" }}
                                    >
                                      End Time
                                    </label>
                                    <div style={{ marginLeft: "11px" }}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <TimePicker
                                          // open={openEndTime}
                                          // onOpen={() => setOpenEndTime(true)}
                                          // onClose={handleEndTimeClose}

                                          closeOnSelect={false}
                                          value={
                                            formik.values.end_date_time
                                              ? dayjs(
                                                  `2022-01-01T${formik.values.end_date_time}`
                                                )
                                              : null
                                          }
                                          onChange={(newValue) => {
                                            if (newValue) {
                                              formik.setFieldValue(
                                                "end_date_time",
                                                newValue.format("HH:mm")
                                              );
                                            } else {
                                              formik.setFieldValue(
                                                "end_date_time",
                                                null
                                              );
                                            }
                                          }}
                                          minTime={minTimefortime}
                                          views={["hours", "minutes"]}
                                        />
                                      </LocalizationProvider>{" "}
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  {(!customer_id || !location_id) && (
                    <React.Fragment>
                      <div className="card-body">
                        <p className="text-start mt-4 mb-4 badge-light-danger">
                          Customer/Location Id missing.
                        </p>
                      </div>
                    </React.Fragment>
                  )}
                </form>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = (e) => {
    e.preventDefault();

    let isValid = false;
    const hasErrors =
      formik.errors.email ||
      formik.errors.last_name ||
      formik.errors.full_name ||
      formik.errors.identification ||
      formik.errors.identification_type ||
      formik.errors.phone_number ||
      formik.errors.company_name ||
      formik.errors.address;
    switch (activeStep) {
      case 0:
        isValid =
          formik.values.purpose_of_visit &&
          formik.values.visitor_type &&
          formik.values.location;
        break;

      case 1:
        isValid =
          !hasErrors &&
          formik.values.full_name &&
          formik.values.last_name &&
          formik.values.email &&
          formik.values.phone_number &&
          formik.values.company_name &&
          formik.values.address &&
          formik.values.identification_type;
        formik.setTouched({
          email: true,
          last_name: true,
          full_name: true,
          identification: true,
          identification_type: true,
          phone_number: true,
          company_name: true,
          address: true,
        });

        formik.validateForm().then(() => {
          setShowTooltipEmail(!!formik.errors.email);
          setShowTooltipLastName(!!formik.errors.last_name);
          setShowTooltipfirstname(!!formik.errors.full_name);
          setShowTooltipidentificationNo(!!formik.errors.identification);
          setShowTooltipidentificationType(!!formik.errors.identification_type);
          setShowTooltipcontactnumber(!!formik.errors.phone_number);
          setShowCopanyname(!!formik.errors.company_name);
          setShowTooltipaddress(!!formik.errors.address);

          if (!hasErrors) {
            setActiveStep(activeStep + 1);
          }
        });

        break;
      case 2:
        isValid =
          formik.values.visiting_date &&
          formik.values.visiting_time &&
          formik.values.duration;
        break;
      default:
        break;
    }

    if (isValid) {
      setActiveStep(activeStep + 1);
    } else {
    }
  };

  return (
    <div>
      <div className="modal-header">
        <button
          type="button"
          className="btn-close btn-active-icon-white"
          id="modalCloseBtn"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
          style={{
            position: "absolute",
            top: "30px",
            right: "1cm",
            background: "#D41920",
            color: "#000",
            display: "flex",
            cursor:"pointer",
            alignItems: "center",
            justifyContent: "center",
            width: "10px",
            height: "10px",
          }}
        >
          <i className="fa fa-times"></i>
        </button>
      </div>
      <Stepper steps={steps} activeStep={activeStep} />
      <div style={{ padding: "2px" }}>
        {getSectionComponent()}
        <div
          className="card-footer float-end m-2"
          style={{ display: "flex", gap: "0.3cm" }}
        >
          {activeStep !== 0 && activeStep !== steps.length - 1 && (
            <button onClick={handlePrevious} className="btn btn-danger">
              Back
            </button>
          )}
          {activeStep === 0 && (
            <button
              type="button"
              id="modalCloseBtn"
              className="btn btn-danger"
              onClick={(e) => {
                setSelectedItem(null);
                setModalData(false);
                formik.resetForm(); 
              }}
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Back
            </button>
          )}
          {activeStep !== steps.length - 1 && (
            <button
              onClick={handleNext}
              type="submit"
              className="btn btn-primary"
            >
              Next
            </button>
          )}
        </div>
        {activeStep === steps.length - 1 && (
          <>
            <div
              className="card-footer float-center m-2"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "0.3cm",
              }}
            ></div>
          </>
        )}
      </div>

      <div
        className="card-footer float-center m-2"
        style={{
          display: "flex",
          justifyContent: "end",
          gap: "0.3cm",
        }}
      >
        {activeStep === 2 && (
          <button onClick={handlePrevious} className="btn btn-danger">
            Back
          </button>
        )}
        {activeStep === 2 && (
          <button
            type="submit"
            onClick={formik.handleSubmit}
            className="btn btn-primary"
          >
            <span className="indicator-label">Save</span>
          </button>
        )}
      </div>
    </div>
  );
};
export default AddVisitorInfo;