/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from "react";
import { SmartTable } from "../../../sdb/layout/components/smart-table/smart-table";
import { useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from "../../../sdb/layout/core";

const Access = () => {
  const [activeView, setActiveView] = useState("UserGroup");
  const smartTableRef: any = useRef(null);
  const history = useNavigate();
  const location: any = useLocation();
  const cId = location.pathname.split("/")[2];

  const columnDefOne = [
    {
      name: "Id",
      dataKey: "_id",
      className: "min-w-150px",
      display: "text",
    },
    {
      name: "Name",
      dataKey: "name",
      className: "min-w-150px",
      display: "text",
    },
    {
      name: "Is Active",
      dataKey: "is_active",
      className: "min-w-150px",
      display: "status",
    },
    {
      name: "Access Key",
      dataKey: "access_key",
      className: "min-w-150px",
      display: "text",
    },
    {
      display: "action",
      name: "Action",
      dataKey: "action",
      className: "min-w-90px",
      actions: [{ label: "View" }, { label: "Edit" }],
    },
  ];
  const columnDef = [
    {
      name: "Id",
      dataKey: "_id",
      className: "min-w-150px",
      display: "text",
    },
    {
      name: "Name",
      dataKey: "name",
      className: "min-w-150px",
      display: "text",
    },
    {
      name: "Is Active",
      dataKey: "is_active",
      className: "min-w-150px",
      display: "status",
    },
    {
      name: "Access Key",
      dataKey: "access_key",
      className: "min-w-150px",
      display: "text",
    },
    {
      display: "action",
      name: "Action",
      dataKey: "action",
      className: "min-w-90px",
      actions: [{ label: "View" }, { label: "Edit" }],
    },
  ];

  const actionCallBack = (data: any) => {
    if (data.action === "add") {
      history("/tenants/usergroup/add", {
        state: { id: cId, from: "add" },
      });
    } else if (data.action === "edit") {
      const row = data?.rowData?.item;
      row["cId"] = cId;
      history(`/customers/${row._id}/edit`, { state: row });
    } else if (data.action === "view") {
      const row = data?.rowData?.item;
      row["cId"] = cId;
      history(`/customers/${row._id}/view`, { state: row });
    }
  };
  const actionCallBackOne = (data: any) => {
    if (data.action === "add") {
      history("/tenants/api/add", {
        state: { id: cId, from: "add" },
      });
    } else if (data.action === "edit") {
      const row = data?.rowData?.item;
      row["cId"] = cId;
      history(`/customers/api/${row._id}/edit`, { state: row });
    } else if (data.action === "view") {
      const row = data?.rowData?.item;
      row["cId"] = cId;
      history(`/customers/api/${row._id}/view`, { state: row });
    }
  };

  return (
    <>
      <div className="d-flex flex-wrap flex-stack ">
        {/* SDB-6(  Braedcrumb Name changed:Customers to Tenants) */}

        <PageTitle
          breadcrumbs={[
            {
              title: "Tenants",
              path: "/tenants",
              isSeparator: false,
              isActive: false,
            },
            {
              title: "Manage Tenants",
              path: "",
              isSeparator: true,
              isActive: false,
            },
          ]}
        >
          {/* SDB-6( Name changed:Access to  User API permissions ) */}
          User API permissions
        </PageTitle>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="d-flex overflow-auto">
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
              {/* SDB-8:("User group field removed") */}

              {/* <li className="nav-item">
                <a
                  onClick={() => setActiveView("UserGroup")}
                  className={
                    `nav-link text-active-primary me-6 c-p ` +
                    (activeView === "UserGroup" && "active")
                  }
                >
                  UserGroup
                </a>
              </li>  */}

              <li className="nav-item">
                <a
                  // onClick={() => setActiveView("API")}
                  className={
                    `nav-link  text-active-primary me-6 c-p ` +
                    (activeView === "API" && "active")
                  }
                >
                  API
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        {/* {activeView !== "API" && (
          <SmartTable
            ref={smartTableRef}
            getURL={`/user-group/customer_id/${cId}/`}
            serachPlaceholder="Serach"
            bulkdelete="/user-group/delete/bulk/"
            addBtnText="Add"
            imagePathForNoRecord="/media/location.png"
            noRecordHelpText="Click on the below button to add your Usergroup"
            actionCallBack={actionCallBack}
            cols={columnDefOne}
            multiSelectRequired={true}
          />
        )}  */}
        {/* SDB-Default data of API showed */}

        {activeView !== "API" && (
          <SmartTable
            ref={smartTableRef}
            getURL={`/user-group/access_key/customer_id/${cId}/`}
            serachPlaceholder="Search"
            addBtnText="Add"
            wantadd={true}
            hideActions={true}
            imagePathForNoRecord="/media/location.png"
            noRecordHelpText="Click on the below button to add your API"
            actionCallBack={actionCallBackOne}
            cols={columnDef}
            multiSelectRequired={false}
          />
        )}
      </div>
    </>
  );
};

export default Access;
