import React, { useState, useEffect } from "react";
import { getEndpoints, addUsergroup, updateUsergroup } from "./requests";
import MultiSelect from "@kenshooui/react-multi-select";
import { useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import { PageTitle } from "../../../sdb/layout/core";

export function AddApi() {
  const [endpointList, setEndpointList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [newEndPoints, setNewEndPoints] = useState([]);
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const history = useNavigate();

  const location: any = useLocation();
  let apiData = location.state;

  const handleChange = (selectedItems: any) => {
    setSelectedItems(selectedItems);
    const modifiedData = selectedItems?.map((item: any) => ({
      route: item.route,
      method: item.method,
      name: item.name,
    }));
    setNewEndPoints(modifiedData);
  };

  const getEndpointsData = async () => {
    try {
      const { data } = await getEndpoints();
      if (data) {
        const newData = data.map((item: any) => ({
          id: item.route,
          label: item.tags + " | " + item.name,

          disabled: false,
          ...item,
        }));
        setEndpointList(newData);
        
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const onSubmit = async (values: any) => {
    try {
      const newValues = {
        name: name,
        permission: [...values],
        description: description,
        customer_id: apiData.id,
        is_active: true,
        access_key: true,
      };
      await addUsergroup(newValues);
      setEndpointList([]);
      setSelectedItems([]);
      setDescription("");
      setName("");
      history(`/tenants/${apiData.id}/access`);
    } catch (err) {
      console.log(err, "err");
    }
  };

  const onUpdate = async (values: any) => {
    try {
      const newValues = {
        name: name,
        permission: [...values],
        description: description,
        customer_id: apiData._id,
        is_active: true,
      };
      await updateUsergroup(newValues, apiData._id);
      setEndpointList([]);
      setSelectedItems([]);
      setDescription("");
      setName("");
      history(`/tenants/${apiData.cId}/access`);
    } catch (err) {
      console.log(err, "err");
    }
  };

  const setDescriptionValue = (value: any) => {
    setDescription(value);
  };
  const setNameValue = (value: any) => {
    setName(value);
  };

  useEffect(() => {
    if (apiData) {
      const newCustomerData = apiData?.permission?.map((item: any) => ({
        id: item.name,
        label: item.name,
        disabled: false,
        ...item,
      }));
      setSelectedItems(newCustomerData);
      setDescription(apiData.description);
      setName(apiData.name);
    }

    getEndpointsData();
  }, []);
  return (
    <>
      <div className="d-flex flex-wrap flex-stack mb-6">
        {/* SDB-6:(Breadcrumb name changed  Customers--->Tenants ,Access--->user API Permissions) */}
        <PageTitle
          breadcrumbs={[
            {
              title: "Tenants",
              path: "/tenants",
              isSeparator: false,
              isActive: false,
            },
            {
              title: "",
              path: "",
              isSeparator: true,
              isActive: false,
            },
            {
              title: "User API Permissions",
              path:
                apiData.from == "add"
                  ? `/tenants/${apiData.id}/access`
                  : `/tenants/${apiData.cId}/access`,
              isSeparator: false,
              isActive: false,
            },
            {
              title: "",
              path: "",
              isSeparator: true,
              isActive: false,
            },
          ]}
        >
          {apiData.from == "add" ? "Add API" : "Edit API"}
        </PageTitle>
      </div>
      <div className="row">
        <div className="d-flex mb-4">
          <div className="col-6 me-4">
            <label className=" fw-bold fs-6 mb-2 required">Name</label>
            <input
              type="text"
              name="name"
              className={clsx("form-control form-control-solid mb-3 mb-lg-0", {
                "is-invalid": name === "",
              })}
              autoComplete="off"
              onChange={(e) => setNameValue(e.target.value)}
              value={name}
            />
          </div>
          <div className="col-6">
            <label className=" fw-bold fs-6 mb-2">Description</label>
            <textarea
              className="form-control form-control-solid mb-3"
              rows={3}
              data-kt-element="input"
              onChange={(e) => setDescriptionValue(e.target.value)}
              value={description}
            ></textarea>
          </div>
        </div>
        <MultiSelect
          items={endpointList}
          selectedItems={selectedItems}
          onChange={handleChange}
        />

        <div className="text-end mt-7">
          <button
            className="btn btn-light"
            onClick={() => {
              history(`/tenants/${apiData.id}/access`);
            }}
          >
            Back
          </button>
          {apiData.from == "add" ? (
            <button
              className="btn btn-primary"
              style={{ marginLeft: "12px" }}
              onClick={() => onSubmit(newEndPoints)}
              disabled={!name ||!description || newEndPoints.length === 0}
              >
              Save
            </button>
          ) : (
            <button
              className="btn btn-primary"
              style={{ marginLeft: "12px" }}
              onClick={() => onUpdate(newEndPoints)}
              disabled={name === "" || selectedItems.length === 0}
            >
              Update
            </button>
          )}
        </div>
      </div>
    </>
  );
}
