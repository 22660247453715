import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { KTIcon } from "../../../sdb/helpers";
import Select from "react-select";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import clsx from "clsx";
import Modal from "react-bootstrap/Modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  addCustomer,
  updateCustomer,
  uuidv4,
  updateCustomerAdmin,
  deleteCustomerAdmin,
} from "./requests";
import { PageTitle } from "../../../sdb/layout/core";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const editUserSchema = Yup.object().shape({
  // address: Yup.string().required("Primary Address is required"),
  name: Yup.string()
    .matches(
      /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
      "Please enter alphabets with only one space between words"
    )
    .required("Tenant name is required")
    .test(
      "no-multiple-spaces",
      "Please do not use multiple spaces between words",
      (value) => {
        if (!value) return true; // Handles the required check
        return !/\s{2,}/.test(value); // Ensures no multiple spaces
      }
    ),

  // is_active: Yup.object({
  //   value: Yup.string().required("Status is required"),
  // }),
  contact: Yup.string()
    .matches(/^[a-zA-Z]+$/, "Please enter alphabets")
    .required("Tenant contact name is required"),
  notes: Yup.string().required("Note is required"),
  contact_email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._@-]+$/,
      "Email address can only contain alphanumeric characters and special characters like - _ . @"
    )
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|asia)$/,
      "Invalid email format"
    )
    .email("Invalid email format")
    .required("Tenant Email is required"),

  contact_phone: Yup.string()
    .matches(/^[0-9+ ]+$/, "Tenant Contact Number should contain only numbers")
    .min(10, "Tenant Contact Number must be at least 10 characters")
    .max(15, "Tenant Contact Number must be at most 15 characters")
    .required("Tenant Contact Number is required"),

  // tenant_admin_name: Yup.string()
  //   .matches(/^[a-zA-Z]+$/, "Please enter alphabets")
  //   .required("Admin firstname is required"),

  // tenant_admin_lastname: Yup.string()
  //   .matches(/^[a-zA-Z]+$/, "Please enter alphabets")
  //   .required("Admin lastname  is required"),

  // tenant_admin_email: Yup.string()
  //   .matches(
  //     /^[a-zA-Z0-9._@-]+$/,
  //     "Email address can only contain alphanumeric characters and special characters like - _ . @"
  //   )

  //   .matches(
  //     /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|asia)$/,
  //     "Invalid email format"
  //   )
  //   .email("Invalid email format")
  //   .required("Admin Email is required"),
});
const formatPhoneNumber2 = (phone) => {
  // Remove all non-numeric characters
  const cleaned = phone.replace(/\D/g, "");

  // Optionally, you could handle country code here if needed
  // For example, if the number starts with '91' (India's country code), remove it
  // Uncomment the next lines if you want to remove the country code
  // if (cleaned.startsWith('91')) {
  //   return cleaned.slice(2); // Remove the country code
  // }

  return cleaned;
};

const createUserSchema = Yup.object().shape({
  // address: Yup.string().required("Primary Address is required"),
  name: Yup.string()
    .matches(
      /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
      "Please enter alphabets with only one space between words"
    )
    .required("Tenant name is required")
    .test(
      "no-multiple-spaces",
      "Please do not use multiple spaces between words",
      (value) => {
        if (!value) return true; // Handles the required check
        return !/\s{2,}/.test(value); // Ensures no multiple spaces
      }
    ),

  // is_active: Yup.object({
  //   value: Yup.string().required("Status is required"),
  // }),
  contact: Yup.string()
    .matches(/^[a-zA-Z]+$/, "Please enter alphabets")
    .required("Tenant contact name is required"),
  notes: Yup.string().required("Note is required"),
  contact_email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._@-]+$/,
      "Email address can only contain alphanumeric characters and special characters like - _ . @"
    )
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|asia)$/,
      "Invalid email format"
    )
    .email("Invalid email format")
    .required("Tenant Email is required"),

  contact_phone: Yup.string()
    .matches(/^[0-9+ ]+$/, "Tenant Contact Number should contain only numbers")
    .min(10, "Tenant Contact Number must be at least 10 characters")
    .max(15, "Tenant Contact Number must be at most 15 characters")
    .required("Tenant Contact Number is required"),

  tenant_admin_name: Yup.string()
    .matches(/^[a-zA-Z]+$/, "Please enter alphabets")
    .required("Admin firstname is required"),

  tenant_admin_lastname: Yup.string()
    .matches(/^[a-zA-Z]+$/, "Please enter alphabets")
    .required("Admin lastname  is required"),

  tenant_admin_email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._@-]+$/,
      "Email address can only contain alphanumeric characters and special characters like - _ . @"
    )
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|asia)$/,
      "Invalid email format"
    )
    .email("Invalid email format")
    .required("Admin Email is required"),
});
interface SelectedItem {
  id: string | null;
  name: string;
  last_name: string;
  email: string;
}
export function ViewTenants(props) {
  const history: any = useNavigate();

  const location: any = useLocation();
  const [customerData, setCustomerData]: any = useState({});
  const [adminItems, setAdminList]: any = useState([]);
  const [selectedItem, setSelectedItem] = useState<SelectedItem>({
    id: null,
    name: "",
    last_name: "",
    email: "",
  });
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [contactNumberError, setContactNumberError] = useState("");
  const handleModalSave = async (e) => {
    e.preventDefault(); 
  
    const errors = await formik.validateForm();
  
    if (errors.tenant_admin_email||errors.tenant_admin_name||errors.tenant_admin_lastname ) {
      setShowTooltipadminfirstname(!!formik.errors.tenant_admin_name);
      setShowTooltiplastadminname(!!formik.errors.tenant_admin_lastname);
      setShowTooltipadminemail(!!formik.errors.tenant_admin_email);
      return;
    }
  
    if (customerData._id) {
      updateCustomerAdmin(selectedItem.email)
        .then(() => {
          let adminList = [...adminItems];
          let itemIndex = adminItems.findIndex((x) => x.id === selectedItem.id);
          if (itemIndex !== -1) {
            adminList[itemIndex] = { ...selectedItem };
          } else {
            adminList.push(selectedItem);
          }
          setShowModal(false);
          setAdminList(adminList);
          setSelectedItem({
            id: null,
            name: "",
            last_name: "",
            email: "",
          });
        })
        .catch((e) => {
          Swal.fire("User Info!", e?.response?.data?.detail, "error");
        });
    } else {
      let adminList = [...adminItems];
      let itemIndex = adminItems.findIndex((x) => x.id === selectedItem.id);
      if (itemIndex !== -1) {
        adminList[itemIndex] = { ...selectedItem };
      } else {
        adminList.push(selectedItem);
      }
      setShowModal(false);
      setAdminList(adminList);
      setSelectedItem({
        id: null,
        name: "",
        last_name: "",
        email: "",
      });
    }
  
    setShowTooltipadminfirstname(false);
    setShowTooltiplastadminname(false);
    setShowTooltipadminemail(false);





  };
  
  const deleteAdmin = (args) => {
    setIsDeleting(true);
    if (customerData._id) {
      deleteCustomerAdmin(args.email)
        .then((r) => {
          let adminList = [...adminItems];
          adminList = adminList.filter((x) => x.id !== args.id);
          setAdminList(adminList);
        })
        .catch((e) => {
          Swal.fire("Deleted!", e?.response?.data?.detail, "error");
        })
        .finally(() => {
          setIsDeleting(false);
        });
    } else {
      let adminList = [...adminItems];
      adminList = adminList.filter((x) => x.id !== args.id);
      setAdminList(adminList);
      // console.log(adminItems);

      setIsDeleting(true);
    }
  };

  const [customerInfo] = useState<any>({
    name: customerData.name || "",
    address: (customerData.address && customerData.address[0]) || "",

    is_active:
      customerData.is_active === false
        ? { value: "0", label: "Inactive" }
        : { value: "1", label: "Active" },
    notes: customerData.notes || "",
    contact_email: customerData.contact_email || "",
    contact_phone: customerData.contact_phone || "",
    tenant_admin_name: customerData.tenant_admin_name || "",
    tenant_admin_lastname: customerData.tenant_admin_lastname || "",

    tenant_admin_email: customerData.tenant_admin_email || "",
  });

  const formik: any = useFormik({
    initialValues: customerInfo,
    validationSchema: customerData._id ? editUserSchema : createUserSchema,
    onSubmit: async (values: any, { setSubmitting }) => {
      setSubmitting(true);
      try {
        values["address"] = [values["address"]];
        values["is_active"] = values.is_active.value == "0" ? false : true;
        // values["tenant_admin"] = values.tenant_admin.value == "0" ? false : true;

        values["admin"] = adminItems;
        var params = {
          name: values.name,
          address: [values["address"]],
          contact_email: values.contact_email,
          contact_phone: values.contact_phone,
          tenant_admin_name: values.tenant_admin_name,
          tenant_admin_lastname: values.tenant_admin_lastname,
          tenant_admin_email: values.tenant_admin_email,

          contact: values.contact,
          notes: values.notes,
          is_active: values.is_active.value == "0" ? false : true,
        };

        const transformedAdminItems = adminItems.map((item) => ({
          ...item,
          email: item.email.toLowerCase(),
          name:
            item.name.charAt(0).toUpperCase() +
            item.name.slice(1).toLowerCase(),
        }));

        const updatedValues = {
          ...values,
          contact_email: values.contact_email.toLowerCase(),
          tenant_admin_email: values.tenant_admin_email.toLowerCase(),
          admin: transformedAdminItems,
        };
        const data: Promise<any> = (await customerData._id)
          ? updateCustomer(params, customerData._id)
          : addCustomer(updatedValues);

        data
          .then((res: any) => {
            history("/tenants");
          })
          .catch((error: any) => {
            let errorMessage = "";
            if (
              Array.isArray(error.response.data.detail) &&
              error.response.data.detail.length > 0
            ) {
              errorMessage = error.response.data.detail[0].msg;
            } else {
              errorMessage = error.response.data.detail;
            }

            Swal.fire("Tenant!", errorMessage, "error");
          });
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(true);
      }
    },
  });
  useEffect(() => {
    const selectedCustoemr = location.state;
    if (selectedCustoemr) {
      setCustomerData(selectedCustoemr);
      let adminList = selectedCustoemr.admin;
      adminList.forEach((item) => {
        item["id"] = uuidv4();
      });
      setAdminList(adminList);
      let patch_object = {
        name: selectedCustoemr.name || "",
        address:
          (selectedCustoemr.address && selectedCustoemr.address[0]) || "",

        is_active:
          selectedCustoemr.is_active === false
            ? { value: "0", label: "Inactive" }
            : { value: "1", label: "Active" },
        notes: selectedCustoemr.notes || "",
        contact: selectedCustoemr.contact || "",
        contact_email: selectedCustoemr.contact_email || "",
        contact_phone: selectedCustoemr.contact_phone || "",
        tenant_admin_name: selectedCustoemr.tenant_admin_name || "",
        tenant_admin_lastname: selectedCustoemr.tenant_admin_lastname || "",

        tenant_admin_email: selectedCustoemr.tenant_admin_email || "",
      };

      formik.setValues(patch_object);
    }
  }, []);

  const [showTooltipfirstname, setShowTooltipfirstname] = useState(false);
  const [showTooltipLastName, setShowTooltipLastName] = useState(false);
  const [showTooltipEmail, setShowTooltipEmail] = useState(false);
  const [ShowTooltipcontactnumber, setShowTooltipcontactnumber] =
    useState(false);

  const [showTooltipadminfirstname, setShowTooltipadminfirstname] =
    useState(false);
  const [showTooltipadminlastname, setShowTooltiplastadminname] =
    useState(false);
  const [showTooltipadminemail, setShowTooltipadminemail] = useState(false);

  const hasErrorLastName = formik.touched.contact && formik.errors.contact;
  const hasErrorfirstName = formik.touched.name && formik.errors.name;
  const hasErrorEmail =
    formik.touched.contact_email && formik.errors.contact_email;

  const hasErrorcontactnumber =
    formik.touched.contact_phone && formik.errors.contact_phone;

  const hasErroradminfirstName =
    formik.touched.tenant_admin_name && formik.errors.tenant_admin_name;
  const hasErroradminLastName =
    formik.touched.tenant_admin_lastname && formik.errors.tenant_admin_lastname;
  const hasErroradminemail =
    formik.touched.tenant_admin_email && formik.errors.tenant_admin_email;

  const renderTooltipLastName = (props) => (
    <Tooltip id="lastNameTooltip" {...props}>
      {formik.errors.contact}
    </Tooltip>
  );

  const renderTooltipfirstname = (props) => (
    <Tooltip id="fistnameTooltip" {...props}>
      {formik.errors.name}
    </Tooltip>
  );
  const renderTooltipEmail = (props) => (
    <Tooltip id="emailTooltip" {...props}>
      {formik.errors.contact_email}
    </Tooltip>
  );
  const renderTooltipcontactnumber = (props) => (
    <Tooltip id="phonenumebrTooltip" {...props}>
      {formik.errors.contact_phone}
    </Tooltip>
  );

  const renderTooltipadminfirstname = (props) => (
    <Tooltip id="adminnameTooltip" {...props}>
      {formik.errors.tenant_admin_name}
    </Tooltip>
  );
  const renderTooltiadminLastName = (props) => (
    <Tooltip id="adminlastNameTooltip" {...props}>
      {formik.errors.tenant_admin_lastname}
    </Tooltip>
  );

  const renderTooltipadminEmail = (props) => (
    <Tooltip id="adminemailTooltip" {...props}>
      {formik.errors.tenant_admin_email}
    </Tooltip>
  );
  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">
              {/* SDB:6(Name changed:"Add Admin"--->"Add Tenant Admin") */}
              Add Tenant Admin
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {customerData._id ? (
              ""
            ) : (
              <div className="row">
                <div className="col-lg-6">
                  <label className="col-form-label required">
                    Tenant Admin Firstname
                  </label>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltipadminfirstname}
                    show={showTooltipadminfirstname && hasErroradminfirstName}
                  >
                    <input
                      type="text"
                      {...formik.getFieldProps("tenant_admin_name")}
                      name="tenant_admin_name"
                      onChange={(e) => {
                        let item = { ...selectedItem };
                        item.name = e.target.value;
                        setSelectedItem(item);
                        formik.handleChange(e); // Ensure formik handleChange is called
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                      }}
                      value={selectedItem.name}
                      autoComplete="off"
                      maxLength={15}
                      className={clsx(
                        "form-control form-control-solid mb-3 mb-lg-0",
                        {
                          "is-invalid":
                            formik.touched.tenant_admin_name &&
                            formik.errors.tenant_admin_name,
                        },
                        {
                          "is-valid":
                            formik.touched.tenant_admin_name &&
                            !formik.errors.tenant_admin_name,
                        }
                      )}
                    />
                  </OverlayTrigger>
                  {formik.touched.tenant_admin_name &&
                    formik.errors.tenant_admin_name && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {formik.errors.tenant_admin_name}
                          </span>
                        </div>
                      </div>
                    )}
                </div>

                <div className="col-lg-6">
                  {/* SDB:6(Name changed:"Name"--->" Tenant name") */}
                  <label className="col-form-label required">
                    Tenant Admin Lastname
                  </label>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltiadminLastName}
                    show={showTooltipadminlastname && hasErroradminLastName}
                  >
                    <input
                      type="text"
                      {...formik.getFieldProps("tenant_admin_lastname")}
                      name="tenant_admin_lastname"
                      onChange={(e) => {
                        let item = { ...selectedItem };
                        item.last_name = e.target.value;

                        setSelectedItem(item);
                        formik.handleChange(e); // Ensure formik handleChange is called
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                      }}
                      value={selectedItem.last_name}
                      autoComplete="off"
                      maxLength={15}
                      className={clsx(
                        "form-control form-control-solid mb-3 mb-lg-0",
                        {
                          "is-invalid":
                            formik.touched.tenant_admin_lastname &&
                            formik.errors.tenant_admin_lastname,
                        },
                        {
                          "is-valid":
                            formik.touched.tenant_admin_lastname &&
                            !formik.errors.tenant_admin_lastname,
                        }
                      )}
                    />
                  </OverlayTrigger>

                  {formik.touched.tenant_admin_lastname &&
                    formik.errors.tenant_admin_lastname && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {String(formik.errors.tenant_admin_lastname)}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}

            <div className="mb-3">
              <label className="col-form-label required">
                {" "}
                Tenant Admin Email
              </label>
              <OverlayTrigger
                placement="top"
                overlay={renderTooltipadminEmail}
                show={showTooltipadminemail && hasErroradminemail}
              >
                <input
                  type="email"
                  {...formik.getFieldProps("tenant_admin_email")}
                  name="tenant_admin_email"
                  maxLength={50}
                  onChange={(e) => {
                    let item = { ...selectedItem };
                    item.email = e.target.value;
                    setSelectedItem(item);
                    formik.handleChange(e); // Ensure formik handleChange is called
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                  value={selectedItem.email}
                  autoComplete="off"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    {
                      "is-invalid":
                        formik.touched.tenant_admin_email &&
                        formik.errors.tenant_admin_email,
                    },
                    {
                      "is-valid":
                        formik.touched.tenant_admin_email &&
                        !formik.errors.tenant_admin_email,
                    }
                  )}
                />
              </OverlayTrigger>
              {formik.touched.tenant_admin_email &&
                formik.errors.tenant_admin_email && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">
                        {String(formik.errors.tenant_admin_email)}
                      </span>
                    </div>
                  </div>
                )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleClose}
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleModalSave}
            disabled={
              customerData._id
                ? !selectedItem.email
                : !selectedItem.name ||
                  !selectedItem.email ||
                  !selectedItem.last_name
            }
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
      {/* SDB-6(Breadcrumb Name changed:Customers to Tenants,Manage Customers--->Manage Tenants ) */}
      <div className="d-flex flex-wrap flex-stack mb-6">
        <PageTitle
          breadcrumbs={[
            {
              title: "Tenants",
              path: "/tenants",
              isSeparator: false,
              isActive: false,
            },
            {
              title: "Manage Tenants",
              path: "",
              isSeparator: true,
              isActive: false,
            },
          ]}
        >
          {/* SDB:6(Name changed:"Add customer"--->"Add Tenant") */}
          {customerData._id ? "Edit Tenant" : "Add Tenant"}
        </PageTitle>
      </div>
      <div className="row">
        <form className="form" onSubmit={formik.handleSubmit} noValidate>
          <div className="col-12 mb-4">
            <div className="card">
              {/* begin::Header */}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">
                    {/* SDB:6(Name changed:"Customer Details"--->"Tenant Details") */}
                    Tenant Details
                  </span>
                </h3>
                <div
                  className="card-toolbar"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-trigger="hover"
                  title="Click to add a user"
                >
                  <button
                    // disabled={
                    //   formik.isSubmitting ||
                    //   !formik.isValid ||
                    //   !formik.values.name ||
                    //   !formik.values.contact ||
                    //   !formik.values.contact_email ||
                    //   !formik.values.contact_phone ||
                    //   (customerData._id
                    //     ? false
                    //     : !selectedItem.name || !selectedItem.email)
                    // }
                    className="btn btn-sm btn-primary"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      formik.setTouched({
                        contact_email: true,
                        contact_phone: true,
                        name: true,
                        contact: true,
                        tenant_admin_name: true,
                        tenant_admin_lastname: true,
                        tenant_admin_email: true,
                      });

                      formik.validateForm().then(() => {
                        const hasErrors = formik.errors.contact_email;
                        const hasErrorscontatcnumber =
                          formik.errors.contact_phone;
                        const hasErrorsname = formik.errors.name;
                        const hasErrorscontactname = formik.errors.contact;

                        setShowTooltipEmail(!!formik.errors.contact_email);
                        setShowTooltipcontactnumber(
                          !!formik.errors.contact_phone
                        );
                        setShowTooltipLastName(!!formik.errors.contact);
                        setShowTooltipfirstname(!!formik.errors.name);
                        if (
                          !hasErrors ||
                          !hasErrorscontatcnumber ||
                          !hasErrorsname ||
                          !hasErrorscontactname
                        ) {
                          formik.handleSubmit();
                        }
                      });
                    }}
                  >
                    {customerData._id ? "Save Changes" : "Save"}
                  </button>
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className="card-body py-3">
                <div className="row">
                  <div className="col-4 mb-3">
                    <label className="required fw-bold fs-6 mb-2">
                      {/* SDB:6(Name changed:"Name"--->" Tenant name") */}
                      Tenant name
                    </label>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        showTooltipfirstname && hasErrorfirstName ? (
                          renderTooltipfirstname
                        ) : (
                          <></>
                        )
                      }
                      show={showTooltipfirstname && hasErrorfirstName}
                    >
                      <input
                        placeholder="Name"
                        {...formik.getFieldProps("name")}
                        type="text"
                        name="name"
                        maxLength={15}
                        className={clsx(
                          "form-control form-control-solid mb-3 mb-lg-0",
                          {
                            "is-invalid":
                              formik.touched?.name && formik.errors?.name,
                          },
                          {
                            "is-valid":
                              formik.touched?.name && !formik.errors?.name,
                          }
                        )}
                        autoComplete="off"
                      />
                    </OverlayTrigger>
                    {/* {formik.touched.name && formik.errors.name && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{String(formik.errors.name)}</span>
                        </div>
                      </div>
                    )} */}
                  </div>
                  <div className="col-4 mb-3">
                    <label className="required fw-bold fs-6 mb-2">
                      {/* SDB:6(Name changed:"Contact"--->" Tenant Contact") */}
                      Tenant Contact Name
                    </label>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        showTooltipLastName && hasErrorLastName ? (
                          renderTooltipLastName
                        ) : (
                          <></>
                        )
                      }
                      show={showTooltipLastName && hasErrorLastName}
                    >
                      <input
                        placeholder="Contact"
                        {...formik.getFieldProps("contact")}
                        type="text"
                        maxLength={15}
                        name="contact"
                        className={clsx(
                          "form-control form-control-solid mb-3 mb-lg-0",
                          {
                            "is-invalid":
                              formik.touched?.contact && formik.errors?.contact,
                          },
                          {
                            "is-valid":
                              formik.touched?.contact &&
                              !formik.errors?.contact,
                          }
                        )}
                        autoComplete="off"
                      />
                    </OverlayTrigger>
                    {/* {formik.touched.contact && formik.errors.contact && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {String(formik.errors.contact)}
                          </span>
                        </div>
                      </div>
                    )} */}
                  </div>
                  <div className="col-4 mb-3">
                    <label className="required fw-bold fs-6 mb-2">Status</label>
                    <Select
                      {...formik.getFieldProps("is_active")}
                      name="is_active"
                      options={[
                        { value: "1", label: "Active" },
                        { value: "0", label: "Inactive" },
                      ]}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        formik.setFieldValue("is_active", e);
                      }}
                    />
                    {/* {formik.touched.is_active && formik.errors.is_active && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {String(formik.errors.is_active)}
                          </span>
                        </div>
                      </div>
                    )} */}
                  </div>

                  {/* SDB-30 (Fields Added: Add Tenant Email and Tenant Phone number ) */}

                  <div className="col-6  mb-3">
                    <label className="required fw-bold fs-6 mb-2">
                      Tenant Email
                    </label>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        showTooltipEmail && hasErrorEmail ? (
                          renderTooltipEmail
                        ) : (
                          <></>
                        )
                      }
                      show={showTooltipEmail && hasErrorEmail}
                    >
                      <input
                        placeholder="Contact"
                        {...formik.getFieldProps("contact_email")}
                        type="email"
                        name="contact_email"
                        maxLength={50}
                        className={clsx(
                          "form-control form-control-solid mb-3 mb-lg-0",
                          {
                            "is-invalid":
                              formik.touched?.contact_email &&
                              formik.errors?.contact_email,
                          },
                          {
                            "is-valid":
                              formik.touched?.contact_email &&
                              !formik.errors?.contact_email,
                          }
                        )}
                        autoComplete="off"
                      />
                    </OverlayTrigger>
                    {/* {formik.touched.contact_email &&
                      formik.errors.contact_email && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">
                              {String(formik.errors.contact_email)}
                            </span>
                          </div>
                        </div>
                      )} */}
                  </div>
                  <div className="col-6 mb-3">
                    <label className="required fw-bold fs-6 mb-2">
                      Tenant Contact Number
                    </label>

                    <OverlayTrigger
                      placement="top"
                      overlay={
                        ShowTooltipcontactnumber && hasErrorcontactnumber ? (
                          renderTooltipcontactnumber
                        ) : (
                          <></>
                        )
                      }
                      show={ShowTooltipcontactnumber && hasErrorcontactnumber}
                    >
                      <PhoneInput
                        country={"in"}
                        value={formatPhoneNumber2(formik.values.contact_phone)}
                        onChange={(phone) => {
                          // Remove non-numeric characters
                          const cleanedPhone = phone.replace(/\D/g, "");
                          formik.setFieldValue("contact_phone", cleanedPhone);
                        }}
                        inputStyle={{
                          padding: "21px 50px",
                          width: "100%",
                        }}
                        inputProps={{
                          name: "contact_phone",
                          required: true,
                          autoFocus: true,
                          className: clsx(
                            "form-control form-control-solid mb-3 mb-lg-0",
                            {
                              "is-invalid":
                                formik.touched?.contact_phone &&
                                formik.errors?.contact_phone,
                              "is-valid":
                                formik.touched?.contact_phone &&
                                !formik.errors?.contact_phone,
                            }
                          ),
                        }}
                      />
                    </OverlayTrigger>
                    {contactNumberError && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{contactNumberError}</span>
                        </div>
                      </div>
                    )}
                    {/* {formik.touched.contact_phone &&
                      formik.errors.contact_phone && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">
                              {String(formik.errors.contact_phone)}
                            </span>
                          </div>
                        </div>
                      )} */}
                  </div>
                  {/*  SDB-30   */}

                  <div className="col-6  mb-3">
                    <label className="required fw-bold fs-6 mb-2">
                      Primary Address
                    </label>
                    <textarea
                      {...formik.getFieldProps("address")}
                      name="address"
                      className={clsx(
                        "form-control form-control-lg form-control-solid",
                        {
                          "is-invalid":
                            formik.touched?.is_active &&
                            formik.errors?.is_active,
                        },
                        {
                          "is-valid":
                            formik.touched?.is_active &&
                            !formik.errors?.is_active,
                        }
                      )}
                      rows={3}
                    ></textarea>
                    {/* {formik.touched.address && formik.errors.address && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {String(formik.errors.address)}
                          </span>
                        </div>
                      </div>
                    )} */}
                  </div>
                  <div className="col-6">
                    <label className="required fw-bold fs-6 mb-2">Note</label>
                    <textarea
                      {...formik.getFieldProps("notes")}
                      name="notes"
                      className={clsx(
                        "form-control form-control-lg form-control-solid",
                        {
                          "is-invalid":
                            formik.touched?.notes && formik.errors?.notes,
                        },
                        {
                          "is-valid":
                            formik.touched?.notes && !formik.errors?.notes,
                        }
                      )}
                      rows={3}
                    ></textarea>
                    {/* {formik.touched.notes && formik.errors.notes && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {String(formik.errors.notes)}
                          </span>
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
              {/* begin::Body */}
            </div>
          </div>
          <div className="col-12">
            <div className="card">
              {/* begin::Header */}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">
                    {/* SDB:6(Name changed:"Admin List"--->"Tenant Admin List") */}
                    Tenant Admin List
                  </span>
                </h3>
                <div
                  // style={{outline:"none",borderStyle:"none",backgroundColor:" #A9D2FF"}}
                  style={{ padding: "0px 0px" }}
                  className="card-toolbar btn btn-sm btn-light-primary"
                  // disabled={ !formik.values.name ||
                  //     !formik.values.contact ||
                  //     formik.values.contact_email.errors ||
                  //     !formik.values.contact_phone
                  //   }

                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-trigger="hover"
                  title="Click to add a user"
                >
                  <a
                    onClick={(e) => {
                      setSelectedItem({
                        name: "",
                        last_name: "",
                        email: "",
                        id: uuidv4(),
                      });
                      setShowModal(true);
                      setShowTooltipfirstname(false);
                      setShowTooltipLastName(false);
                      setShowTooltipcontactnumber(false);
                      setShowTooltipEmail(false);
                    }}
                    className="btn btn-sm btn-light-primary"
                  >
                    <KTIcon iconName="plus" className="fs-3" />
                    {/* SDB:6(Name changed:"Add Admin"--->"Add Tenant Admin") */}
                    Add Tenant Admin
                  </a>
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className="card-body py-3">
                {/* begin::Table container */}
                <div className="table-responsive">
                  {/* begin::Table */}
                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    {/* begin::Table head */}
                    <thead>
                      <tr className="fw-bold text-muted">
                        <th className="min-w-150px">Name</th>
                        <th className="min-w-140px">Email</th>
                        <th className="min-w-100px text-end">Actions</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {adminItems.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item.name}</td>
                            <td>{item.email.toLowerCase()}</td>
                            <td>
                              <div className="d-flex justify-content-end flex-shrink-0">
                                {!customerData._id && (
                                  <a
                                    onClick={(e) => {
                                      setSelectedItem(item);
                                      setShowModal(true);
                                    }}
                                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                  >
                                    <KTIcon
                                      iconName="pencil"
                                      className="fs-3"
                                    />
                                  </a>
                                )}
                                <a
                                  onClick={(e) => {
                                    deleteAdmin(item);
                                  }}
                                  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                >
                                  <KTIcon iconName="trash" className="fs-3" />
                                </a>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
                {/* end::Table container */}
              </div>
              {/* begin::Body */}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
