/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { SmartTable } from "../../../../sdb/layout/components/smart-table/smart-table";
import { PageTitle } from "../../../../sdb/layout/core";

export function ModuelsPage() {
  const smartTableRef = useRef(null);
  const { currentUser } = useAuth();
  const history = useNavigate();
  const columnDef = [
    // SDB-12:("ID" field removed)

    // {
    //   name: "Id",
    //   dataKey: "_id",
    //   className: "min-w-150px",
    //   display: "text",
    // },
    //

    // SDB6: (Name changed :"Name"--->"Module Name)
    {
      name: "SDB Services Name",
      dataKey: "name",
      className: "min-w-150px",
      display: "text",
      sortable: true,
      sortDirection: "asc",
    },
    // SDB-13: (Name changed :"AliseName"--->"Short name)

    {
      name: "Short name",
      dataKey: "meta",
      className: "min-w-150px",
      display: "arrayObject",
      objectKey: "alise",
      sortable: true,
      sortDirection: "asc",
    },
    {
      name: "Version",
      dataKey: "meta",
      className: "min-w-150px",
      display: "arrayObject",
      objectKey: "version",
      sortable: true,
      sortDirection: "asc",
    },
    {
      display: "action",
      name: "Action",
      dataKey: "action",
      className: "min-w-90px",
      actions: [{ label: "View" }],
      sortable: true,
      sortDirection: "asc",
    },
  ];

  const actionCallBack = (data) => {
    if (data.action === "add") {
      history("/modules/add");
    } else if (data.action === "view") {
      const row = data?.rowData?.item;
      history(`/modules/${row?._id}/view`, { state: row });
    }
  };

  const desiredOrder = ["VMS", "PMS", "IAQ"];

  const sortedModules = currentUser?.module?.sort((a, b) => {
    const aIndex = desiredOrder.indexOf(a.meta[0].alise);
    const bIndex = desiredOrder.indexOf(b.meta[0].alise);
    return aIndex - bIndex;
  });

  return (
    <>
      {currentUser.role === "sa" ? (
        <div className="row">
          {/* <div className="d-flex flex-wrap flex-stack mb-0">
            <h3 className="fw-bolder mb-0">Modules</h3>
          </div> */}
          <PageTitle breadcrumbs={[]}>SDB Services</PageTitle>

          <SmartTable
            ref={smartTableRef}
            hideActions={true}
            getURL="/modules/"
            // deleteUrl="/modles/delete/"
            serachPlaceholder="Serach"
            addBtnText="Add"
            imagePathForNoRecord="/media/location.png"
            noRecordHelpText="Click on the below button to add your SDB Services"
            actionCallBack={actionCallBack}
            cols={columnDef}
            multiSelectRequired={true}
          />
        </div>
      ) : (
        <>
          <div className="d-flex flex-wrap flex-stack my-0">
            <h3 className="fw-bolder my-0 ">SDB Services</h3>
          </div>
          <div className="d-flex flex-wrap mt-5">
            {sortedModules?.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  {item.meta[0].alise === "VMS" && (
                    <div
                      className="me-5 c-p"
                      onClick={() => {
                        history("/modules/visitor-management?id=" + item._id);
                      }}
                    >
                      <div className="card py-15 px-20">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <img
                            src="media/VMS.png"
                            alt="vms"
                            className="w-auto h-auto"
                          />
                          <label className="fw-bold mt-4">
                            Visitor Management
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  {item.meta[0].alise === "parking" && (
                    <div
                      className="me-5 c-p"
                      onClick={() => {
                        history("/modules/parking-management");
                      }}
                    >
                      <img
                        src="media/Group 26086277.png"
                        className="card-img-top"
                        alt="..."
                      />
                    </div>
                  )}
                  {item.meta[0].alise === "PMS" && (
                    <div
                      className="me-5 c-p"
                      onClick={() => {
                        history("/modules/pantry-management?id=" + item._id);
                      }}
                    >
                      <div className="card py-15 px-20">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <img
                            src="media/PMS.png"
                            alt="pms"
                            className="w-auto h-auto"
                          />
                          <label className="fw-bold mt-4">
                            Pantry Management
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  {item.meta[0].alise === "IAQ" && (
                    <div
                      className="me-5 c-p"
                      onClick={() => {
                        history("/modules/device-management?id=" + item._id);
                      }}
                    >
                      <div className="card py-15 px-20">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <img
                            src="media/IAQ.png"
                            alt="iaq"
                            className="w-auto h-auto"
                          />
                          <label className="fw-bold mt-4">
                            Indoor Air Quality
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
            {sortedModules?.length === 0 && (
              <div className="col-12 c-p text-center">
                <h5>No SDB Services found</h5>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
