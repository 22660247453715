/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useAuth } from "../auth";
import { ControllerSetup } from "./device-management/ControllerSetup";
import { AlertSetup } from "./device-management/AlertSetup";
import { DeviceData } from "./device-management/DeviceData";
import { AccessControl } from "./device-management/AccessControl";
export function DeviceManagementPage() {
  const { currentUser, logout } = useAuth();
  const [activeView, setActiveView] = useState("control");
  return (
    <>
      <div className="d-flex flex-wrap flex-stack mb-6" style={{display:"none"}}>
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_toolbar" className="app-toolbar py-3  py-lg-0">
            <div
              id="kt_app_toolbar_container"
              className="app-container-fluid d-flex flex-stack"
            >
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                  IAQ Management
                </h1>

                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-0">
                  <li className="breadcrumb-item text-muted">
                    <a
                      href="/modules"
                      className="text-muted text-hover-primary"
                    >
SDB Services                    </a>
                  </li>

                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-400 w-5px h-2px"></span>
                  </li>

                  <li className="breadcrumb-item text-muted">IAQ Management</li>
                </ul>
              </div>
            </div>
          </div>

          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container-fluid" 
            >
              <div className="d-flex h-55px">
                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                  {currentUser.role === "admin" && (
                    <React.Fragment>
                      <li className="nav-item">
                        <a
                          onClick={() => setActiveView("control")}
                          className={
                            `nav-link text-active-primary me-6 c-p ` +
                            (activeView === "control" && "active")
                          }
                        >
                          Controller Setup
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          onClick={() => setActiveView("alert")}
                          className={
                            `nav-link text-active-primary me-6 c-p ` +
                            (activeView === "alert" && "active")
                          }
                        >
                          Alert Setup
                        </a>
                      </li>
                      {/* <li className="nav-item">
                        <a
                          onClick={() => setActiveView("access")}
                          className={
                            `nav-link text-active-primary me-6 c-p ` +
                            (activeView === "access" && "active")
                          }
                        >
                          Access
                        </a>
                      </li> */}
                    </React.Fragment>
                  )}
                  {(currentUser.role === "user" ||
                    currentUser.role === "facility" ||
                    currentUser.role === "admin") && (
                    <li className="nav-item">
                      <a
                        onClick={() => setActiveView("data")}
                        className={
                          `nav-link text-active-primary me-6 c-p ` +
                          (activeView === "data" && "active")
                        }
                      >
                        Data
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              {activeView === "control" && <ControllerSetup />}
              {activeView === "alert" && <AlertSetup />}
              {activeView === "access" && <AccessControl />}
              {activeView === "data" && <DeviceData />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
