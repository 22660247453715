/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
// eslint-disable-next-line jsx-a11y/anchor-is-valid
// eslint-disable-next-line jsx-a11y/img-redundant-alt
import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import Swal from "sweetalert2";
import { useAuth } from "../../../../app/modules/auth";
import { useListView } from "../../../../app/modules/apps/user-management/users-list/core/ListViewProvider";
import { NoRecords } from "../../../../app/modules/pod/components/no-records";
import { deleteAction, deleteLocations, getAction } from "./request";
import ResponsivePagination from "react-responsive-pagination";
import { useDebouncedCallback } from "../../../../app/utils";
// Last
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
// import { Select } from '@material-ui/core';
// Last end

import "./table.css";
import { DateRange } from "react-date-range";

import { format } from "date-fns/format";
import { addDays } from "date-fns";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
// new filters

const API_URL = process.env.REACT_APP_API_URL;

const SmartTable: FC<any> = forwardRef((props, ref) => {
  const [showAdditionalElements, setShowAdditionalElements] = useState(false);
  const actionclick = () => {
    setShowAdditionalElements(
      (prevShowAdditionalElements) => !prevShowAdditionalElements
    );
  };

  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const startTimeRef = useRef<HTMLInputElement>(null);
  const endTimeRef = useRef<HTMLInputElement>(null);

  const handleStartTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStartTime(event.target.value);
    if (endTime !== "") {
      endTimeRef.current?.blur(); // Blur the end time input
    }
  };

  const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndTime(event.target.value);
    if (startTime !== "") {
      startTimeRef.current?.blur();
    }
  };
  const [sortColumn, setSortColumn] = useState<string>("");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const toggleSortDirection = (column) => {
    column.sortDirection = column.sortDirection === "asc" ? "desc" : "asc";
  };

  const renderSortArrow = (column) => {
    if (column.sortable) {
      return column.sortDirection === "asc" ? "▲" : "▼";
    }
    return null;
  };

  const sortData = (column) => {
    const sortedData = [...allRecords].sort((a, b) => {
      if (column.display === "arrayObject") {
        // If the column is an arrayObject, extract the objectKey value for sorting
        const aValue = a[column.dataKey][0][column.objectKey];
        const bValue = b[column.dataKey][0][column.objectKey];
        if (column.sortDirection === "asc") {
          return String(aValue).localeCompare(String(bValue));
        } else {
          return String(bValue).localeCompare(String(aValue));
        }
      } else if (column.dataKey === "meta" && column.objectKey === "version") {
        const aValue = a[column.dataKey][0][column.objectKey];
        const bValue = b[column.dataKey][0][column.objectKey];
        const aValueNumeric = parseFloat(aValue);
        const bValueNumeric = parseFloat(bValue);
        if (column.sortDirection === "asc") {
          return aValueNumeric - bValueNumeric;
        } else {
          return bValueNumeric - aValueNumeric;
        }
      } else {
        if (column.sortDirection === "asc") {
          return String(a[column.dataKey]).localeCompare(
            String(b[column.dataKey])
          );
        } else {
          return String(b[column.dataKey]).localeCompare(
            String(a[column.dataKey])
          );
        }
      }
    });
    setAllRecords(sortedData);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Filtered with Start Time:", startTime, "End Time:", endTime);
  };
  const [Last, setLast] = React.useState("");
  const [Interval, setinterval] = React.useState("");

  const Lastinputchange = (event: SelectChangeEvent) => {
    setLast(event.target.value);
  };

  const Intervalinputchange = (event: SelectChangeEvent) => {
    setinterval(event.target.value);
  };

  // date state
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  // open close
  const [open, setOpen] = useState(false);

  // const refOne: React.RefObject<HTMLElement> = useRef(null);
  const refOne: React.RefObject<HTMLDivElement> = useRef(null);
  // const refOne = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  // Hide on outside click
  const hideOnClickOutside = (e) => {
    // console.log(refOne.current)
    // console.log(e.target)
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };
  const { currentUser, logout } = useAuth();
  const [selectedItems, setSelectedItems]: any = useState([]);
  const [allRecords, setAllRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (currentPage ? currentPage - 1 : 0) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dataSet = allRecords.slice(startIndex, endIndex);
  const [filterCategoryList, setFilterCategoryList] = useState([]);
  const [searchText, setSearchText]: any = useState("");
  const onSearch = useDebouncedCallback(() => {
    let newCategoryList = [...filterCategoryList];
    if (newCategoryList.length > 0) {
      if (searchText) {
        const iText = searchText.toLowerCase();
        if (props.addBtnText === "Add User") {
          newCategoryList = newCategoryList.filter(
            (x: any) =>
              x.email.toLowerCase().includes(iText) ||
              x.first_name.toLowerCase().includes(iText)
          );
        } else {
          newCategoryList = newCategoryList.filter((x: any) =>
            x.name.toLowerCase().includes(iText)
          );
        }
      }
      setAllRecords(newCategoryList);
      setTotalPages(Math.ceil(newCategoryList.length / itemsPerPage));
    }
  }, 500);

  useEffect(() => {
    onSearch();
  }, [searchText]);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  // const capitalizeFirstLetter = (str) => {
  //   if (!str) return '';
  //   return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  // };
  //   const convertToLowerCase = (str) => {
  //     if (!str) return '';
  //     return str.toLowerCase();
  //   };

  //   const processRecord = (record) => {
  //     return {
  //       ...record,
  //       contact: capitalizeFirstLetter(record.contact),
  //       name: capitalizeFirstLetter(record.name),
  //       contact_email: convertToLowerCase(record.contact_email), // Convert email to lowercase
  //       admin: record.admin.map(adminItem => ({
  //         ...adminItem,
  //         name: capitalizeFirstLetter(adminItem.name),
  //       })),
  //     };
  //   };

  // const getRecords = async () => {
  //   try {
  //     const requestUrl = props.passUserId
  //       ? `${props.getURL}${currentUser.customer_id}/`
  //       : props.getURL;
  //     const { data } = await getAction(requestUrl);
  //     if (data) {
  //       // Process the data to capitalize the first letter of 'contact', 'name', and 'admin.name'
  //       // and convert 'email' to lowercase
  //       const processedData = data.map(record => processRecord(record));

  //       setAllRecords(processedData);
  //       setFilterCategoryList(processedData);
  //       setTotalPages(Math.ceil(processedData.length / itemsPerPage));
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const convertToLowerCase = (str) => {
    if (!str) return "";
    return str.toLowerCase();
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const formatPhoneNumber = (phoneNumber: string) => {
    const cleaned = phoneNumber.replace(/\D/g, "");

    let formattedNumber;
    if (cleaned.length > 10) {
      const countryCode = cleaned.substring(0, cleaned.length - 10);
      const number = cleaned.substring(cleaned.length - 10);

      formattedNumber = `+${countryCode} ${number.substring(
        0,
        5
      )} ${number.substring(5)}`;
    } else if (cleaned.length === 10) {
      formattedNumber = `+91 ${cleaned.substring(0, 5)} ${cleaned.substring(
        5
      )}`;
    } else {
      formattedNumber = phoneNumber;
    }

    return formattedNumber;
  };

  const processRecord = (record) => {
    return {
      ...record,
      contact_phone: record.contact_phone
        ? formatPhoneNumber(record.contact_phone)
        : "N/A",

      contact: record.contact ? capitalizeFirstLetter(record.contact) : "N/A",
      name: record.name ? capitalizeFirstLetter(record.name) : "N/A",
      contact_email: record.contact_email
        ? convertToLowerCase(record.contact_email)
        : "N/A",
      admin: record.admin.map((adminItem) => ({
        ...adminItem,
        name: capitalizeFirstLetter(adminItem.name),
      })),
    };
  };

  const processRecord2 = (record) => {
    return {
      ...record,

      email: record.email ? convertToLowerCase(record.email) : "N/A",
    };
  };

  const getRecords = async () => {
    try {
      const requestUrl = props.passUserId
        ? `${props.getURL}${currentUser.customer_id}/`
        : props.getURL;

      const { data } = await getAction(requestUrl);

      if (data) {
        if (props.getURL.includes("/customers/")) {
          const processedData = data.map((record) => processRecord(record));

          setAllRecords(processedData);
          setFilterCategoryList(processedData);
          setTotalPages(Math.ceil(processedData.length / itemsPerPage));
        } else {
          setAllRecords(data);
          setFilterCategoryList(data);
          setTotalPages(Math.ceil(data.length / itemsPerPage));
        }
      }

      if (data) {
        if (props.getURL.includes("/users/")) {
          const processedData = data.map((record) => processRecord2(record));

          setAllRecords(processedData);
          setFilterCategoryList(processedData);
          setTotalPages(Math.ceil(processedData.length / itemsPerPage));
        }
      }



      if (data) {
        if (props.getURL.includes("/zones/")) {
        //  window.location.reload()
        
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteMultipleRecord = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // selectedItems.forEach((item: any) => {
        const deleteItemUrl = `${props.bulkdelete}`;
        deleteLocations(deleteItemUrl, selectedItems)
          .then((r) => {
            let newRecords = [...allRecords];
            const nr = newRecords.filter(
              (ditem: any) => selectedItems.includes(ditem._id) === false
            );
            setAllRecords(nr);
            setFilterCategoryList(nr);
          })
          .catch((error: any) => {
            Swal.fire("Deleted!", error?.response?.data?.detail, "error");
          });
        // });
        setSelectedItems([]);
      }
    });
  };
  const deleteRecord = async (item: any, rowIndex: number) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteItemUrl = `${props.deleteUrl}${item._id}/`;
        deleteAction(deleteItemUrl)
          .then((r) => {
            let newRecords = [...allRecords];
            const nr = newRecords.filter((ditem: any) => ditem._id != item);
            setAllRecords(nr);
            setFilterCategoryList(nr);
            getRecords();
          })
          .catch((error: any) => {
            Swal.fire("Deleted!", error?.response?.data?.detail, "error");
          });
      }
    });
  };
  const addBtnCallBack = () => {
    props.actionCallBack({
      action: "add",
    });
  };
  useImperativeHandle(ref, () => ({
    getRecords: getRecords,
  }));
  const renderCurrentSelection = (col, data, rowIndex) => {
    switch (col.display) {
      case "image":
        return (
          <td className={col.className}>
            <img
              src={`${API_URL}/${data[col.dataKey]}`}
              alt="image"
              className="table-image"
            />
          </td>
        );
      case "array":
        return (
          <td className={col.className} title={data[col.dataKey][0]}>
            {data[col.dataKey][0]}
          </td>
        );
      case "arrayObject":
        return (
          <td className={col.className}>
            {data[col.dataKey][0][col.objectKey]}
          </td>
        );
      case "object":
        return (
          <td
            className={col.className}
            title={data[col.dataKey][col.objectKey]}
          >
            {data[col.dataKey][col.objectKey]}
          </td>
        );
      case "prfoile":
        return (
          <td>
            <div className="d-flex align-items-center">
              {/* begin:: Avatar */}
              <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                <a href="#">
                  {data.profile_pic && data.profile_pic !== "<string>" ? (
                    <div className="symbol-label">
                      <img
                        src={toAbsoluteUrl(`/media/${data.avatar}`)}
                        alt={data.first_name}
                        className="w-100"
                      />
                    </div>
                  ) : (
                    <div
                      className={clsx(
                        "symbol-label fs-3",
                        `bg-light-${data.is_active ? "success" : "danger"}`,
                        `text-${data.is_active ? "success" : "danger"}`
                      )}
                    >
                      {data.first_name.charAt(0)}
                    </div>
                  )}
                </a>
              </div>
              <div className="d-flex flex-column">
                <a
                  href="#"
                  onClick={(e) => e.preventDefault()}
                  className="text-gray-800 text-hover-primary mb-1"
                >
                  {data.first_name}
                </a>
                <span>{data.mobile}</span>
              </div>
            </div>
          </td>
        );
      case "status":
        return (
          <td>
            <div
              className={
                data[col.dataKey]
                  ? "badge badge-light-success fw-bolder"
                  : "badge badge-light-danger fw-bolder"
              }
            >
              {data[col.dataKey] ? "Active" : "Inactive"}
            </div>
          </td>
        );
      case "date":
        return <td>{new Date(data.created_at).toLocaleDateString()}</td>;
      case "action":
        return (
          col.actions.length > 0 && (
            <td>
              <a
                className="btn btn-light btn-active-light-primary btn-sm "
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
                data-bs-toggle="dropdown"
                aria-expanded="true"
                id="dropdownMenuButton1"
                // SDB:(Width  added for Action btn)
                style={{ width: "8rem" }}
              >
                Actions
                <KTIcon iconName="down" className="fs-5 m-0" />
              </a>
              <div
                className="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
                data-kt-menu="flase"
                aria-labelledby="dropdownMenuButton1"
              >
                {col.actions.map((actionItem: any, key) => {
                  if (actionItem.label === "View") {
                    return (
                      <div className="menu-item px-3" key={key}>
                        <a
                          className="menu-link px-3"
                          onClick={() => {
                            props.actionCallBack({
                              action: "view",
                              rowData: { item: data, rowIndex: rowIndex },
                            });
                          }}
                        >
                          View
                        </a>
                      </div>
                    );
                  } else if (actionItem.label === "Edit") {
                    return (
                      <div className="menu-item px-3" key={key}>
                        <a
                          className="menu-link px-3"
                          onClick={() => {
                            props.actionCallBack({
                              action: "edit",
                              rowData: { item: data, rowIndex: rowIndex },
                            });
                          }}
                        >
                          Edit
                        </a>
                      </div>
                    );
                  } else if (actionItem.label === "Delete") {
                    return (
                      <div className="menu-item px-3" key={key}>
                        <a
                          className="menu-link px-3"
                          data-kt-users-table-filter="delete_row"
                          onClick={() => deleteRecord(data, rowIndex)}
                        >
                          Delete
                        </a>
                      </div>
                    );
                  } else if (actionItem.label === "Access") {
                    return (
                      <div className="menu-item px-3" key={key}>
                        <a
                          className="menu-link px-3"
                          onClick={() => {
                            props.actionCallBack({
                              action: "access",
                              rowData: { item: data, rowIndex: rowIndex },
                            });
                          }}
                          style={{ paddingRight: "4cm", width: "100%" }}
                        >
                          {/* SDB-8:(Name changed:Access--->User API Permissions) */}
                          User API <br /> Permissions
                        </a>
                      </div>
                    );
                  } else if (actionItem.label === "Licenses") {
                    return (
                      <div className="menu-item px-3" key={key}>
                        <a
                          className="menu-link px-3"
                          onClick={() => {
                            props.actionCallBack({
                              action: "Licenses",
                              rowData: { item: data, rowIndex: rowIndex },
                            });
                          }}
                        >
                          {/* SDB-6( Name changed:Licenses to Tenants Limits ) */}
                          Tenant Limits
                        </a>
                      </div>
                    );
                  }
                })}
              </div>
            </td>
          )
        );
      default:
        return <td>{data[col.dataKey]}</td>;
    }
  };
  useEffect(() => {
    getRecords();
  }, []);

  return (
    <>
      {allRecords.length == 0 && searchText === null ? (
        <NoRecords
          helpText={props.noRecordHelpText}
          addBtnCallBack={addBtnCallBack}
          imagePage={props.imagePathForNoRecord}
          addButtonText={props.addBtnText}
        />
      ) : (
        <div className="col-12">
          <div className="card">
            <div className="card-header border-0 pt-6">
              <div className="card-title">
                <div className="d-flex align-items-center position-relative my-1">
                  {props.hidesearch ? (
                    ""
                  ) : (
                    <input
                      type="text"
                      data-kt-user-table-filter="search"
                      className="form-control form-control-solid w-250px ps-14"
                      placeholder={props.serachPlaceholder}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  )}
                </div>
              </div>
              <div className="card-toolbar">
                <div
                  className="d-flex justify-content-end"
                  data-kt-user-table-toolbar="base"
                >
                  {props.hideActions ? (
                    ""
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          props.actionCallBack({
                            action: "add",
                          });
                        }}
                      >
                        <KTIcon iconName="plus" className="fs-2" />
                        {props.addBtnText}
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger ms-2"
                        disabled={selectedItems.length == 0}
                        onClick={() => deleteMultipleRecord()}
                      >
                        <KTIcon iconName="trash" className="fs-2" />
                      </button>
                    </>
                  )}

                  {props.wantadd ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        props.actionCallBack({
                          action: "add",
                        });
                      }}
                    >
                      <KTIcon iconName="plus" className="fs-2" />
                      {props.addBtnText}
                    </button>
                  ) : (
                    ""
                  )}

                  {/* SDB[Filters Added] */}
                  {props.hideFilters ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-light-primary me-3 "
                        // className=""

                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        onClick={actionclick}
                      >
                        <i className="ki-duotone ki-filter fs-2">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        Filter
                      </button>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {showAdditionalElements && (
              // Last Filter Begin

              <div className="smart">
                {/* <FormControl
                  variant="filled"
                  sx={{ ml: 1, minWidth: 100, height: "0cm" }}
                  size="small"
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Last
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={Last}
                    onChange={Lastinputchange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>5 Minutes</MenuItem>
                    <MenuItem value={2}>30 minutes</MenuItem>
                    <MenuItem value={3}>1 Hour</MenuItem>
                    <MenuItem value={4}>12 Hours</MenuItem>
                    <MenuItem value={5}>1 Day</MenuItem>
                    <MenuItem value={6}>7 Days</MenuItem>
                    <MenuItem value={7}>30 Days</MenuItem>
                  </Select>
                </FormControl> */}
                {/* Last Filter end */}

                {/* Date filter Begin */}
                <FormControl sx={{ ml: 1 }} id="intervalid">
                  {/* <label htmlFor="">Time Period</label> */}
                  <input
                    value={`${format(
                      range[0].startDate,
                      "dd/MM/yyyy"
                    )} to ${format(range[0].endDate, "dd/MM/yyyy")}`}
                    readOnly
                    className="inputBox"
                    onClick={() => setOpen((open) => !open)}
                    style={{
                      height: "1.2cm",
                      backgroundColor: "#F0F0F0",
                      borderRadius: "4px",
                      borderStyle: "none",
                    }}
                  />
                  <div
                    ref={refOne}
                    style={{
                      position: "absolute",
                      zIndex: 1,
                      borderRadius: "4px",
                    }}
                  >
                    {open && (
                      <DateRange
                        onChange={(item) => setRange([item.selection])}
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                        ranges={range}
                        months={2}
                        direction="horizontal"
                        className="calendarElement"
                      />
                    )}
                  </div>
                </FormControl>

                {/* Date filter end */}
                {/* time filter start */}
                {/* <FormControl sx={{ ml: 1, minWidth: 220 }} id="timerange">
                  <form onSubmit={handleSubmit}>
                    <label>
                      <input
                        type="time"
                        onFocus={(e: any) => {
                          e.target.showPicker();
                        }}
                        style={{
                          width: "3cm",
                          height: "1.2cm",
                          backgroundColor: "#F0F0F0",
                          borderStyle: "none",
                          borderRadius:"4px",
                          outline: "none",
                        }}
                        value={startTime}
                        onChange={handleStartTimeChange}
                        ref={startTimeRef}
                      />
                    </label>
                    <label>
                      <input
                        type="time"  onFocus={(e: any) => {
                          e.target.showPicker();
                        }}
                        value={endTime}
                        style={{
                          width: "3cm",
                          height: "1.2cm",
                          marginLeft: "10px",
                          backgroundColor: "#F0F0F0",
                          borderStyle: "none",
                          borderRadius:"4px",
                          outline: "none",
                        }}
                        onChange={handleEndTimeChange}
                        ref={endTimeRef}
                      />
                    </label>
                   
                  </form>
                </FormControl> */}

                {/* time filter end */}
                <button
                  type="submit"
                  style={{ marginLeft: "10px" }}
                  className="btn btn-light-primary me-3   "
                >
                  Apply
                </button>
              </div>

              // new
            )}

            <div className="card-body py-4 ">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                  <thead>
                    <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                      {props.multiSelectRequired && (
                        <th className="min-w-50px">
                          <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              data-kt-check={
                                selectedItems.length === allRecords.length
                              }
                              data-kt-check-target="#kt_table_users .form-check-input"
                              checked={
                                selectedItems.length === allRecords.length
                              }
                              onChange={(e) => {
                                let newSelectedItem: any = [];
                                if (e.target.checked) {
                                  newSelectedItem = allRecords.map(
                                    (x: any) => x._id
                                  );
                                } else {
                                  newSelectedItem = [];
                                }
                                setSelectedItems(newSelectedItem);
                              }}
                            />
                          </div>
                        </th>
                      )}
                      {props.cols.map((col, i) => {
                        return (
                          <th
                            key={i}
                            className={col.className}
                            onClick={() => {
                              toggleSortDirection(col);
                              sortData(col);
                            }}
                          >
                            {col.name} {renderSortArrow(col)}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody className="text-gray-600 fw-bold">
                    {dataSet.map((item: any, i) => {
                      return (
                        <tr role="row" key={item._id}>
                          {props.multiSelectRequired && (
                            <td>
                              <div className="form-check form-check-custom form-check-solid">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  data-kt-check={selectedItems.includes(
                                    item._id
                                  )}
                                  data-kt-check-target="#kt_table_users .form-check-input"
                                  checked={selectedItems.includes(item._id)}
                                  onChange={(e) => {
                                    let newSelectedItem: any = [
                                      ...selectedItems,
                                    ];
                                    if (e.target.checked) {
                                      newSelectedItem.push(item._id);
                                    } else {
                                      newSelectedItem = newSelectedItem.filter(
                                        (ik: any) => ik != item._id
                                      );
                                    }
                                    setSelectedItems(newSelectedItem);
                                  }}
                                />
                              </div>
                            </td>
                          )}
                          {props.cols.map((col, colIndex) => {
                            return (
                              <React.Fragment key={`${i}_${colIndex}`}>
                                {renderCurrentSelection(col, item, i)}
                              </React.Fragment>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="row mt-2">
                <div className="col-1">
                  <select
                    name="records_per_age"
                    className="form-select form-select-lg form-select-solid w-auto"
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      setItemsPerPage(value);
                      setTotalPages(Math.ceil(allRecords.length / value));
                    }}
                    value={itemsPerPage}
                  >
                    {new Array(10).fill(null).map((i, index) => {
                      const value = 10 * (index + 1);
                      return (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-11">
                  {
                    <ResponsivePagination
                      total={totalPages}
                      current={currentPage}
                      onPageChange={(page) => handlePageChange(page)}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export { SmartTable };
